import styled from 'styled-components';

interface IIconWrapper {
    isActive: boolean;
}

export const IconWrapper = styled.div<IIconWrapper>`
    width: 22px;
    height: 22px;
    background: ${({ theme }) => theme.colors.grey4()};
    border-radius: 50%;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    svg {
        transform: scale(0.7);
    }
    border: 1px solid ${({ theme, isActive }) => (isActive ? theme.colors.yellow() : 'none')};
`;
export const EnvironmentItemButton = styled.a`
    text-decoration: none;
    padding: 6px 14px;
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 10px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    color: ${({ theme }) => theme.colors.grey()};
    &:hover {
        ${IconWrapper} {
            background: #e3e4e8;
        }
    }
`;
