import React, { FC, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { getLanguage } from '@store/System/selectors';
import { useAppDispatch } from '@store';
import { setLanguage } from '@store/System';
import { enumLanguage, localStorageLanguageKey } from '../Main/types';
import RULocaleData from '../../i18/ru.json';
import ENLocaleData from '../../i18/en.json';

const languagesLocale = {
    [enumLanguage.ru]: RULocaleData,
    [enumLanguage.en]: ENLocaleData,
};

const LanguageLayout: FC = ({ children }) => {
    const dispatch = useAppDispatch();

    const localStorageLanguage = localStorage.getItem(localStorageLanguageKey);

    useEffect(() => {
        if (localStorageLanguage) {
            dispatch(setLanguage(localStorageLanguage));
        } else if (navigator?.language?.includes(enumLanguage.en)) {
            dispatch(setLanguage(enumLanguage.en));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentLocale = useSelector(getLanguage);

    return (
        <IntlProvider locale={currentLocale} messages={languagesLocale[currentLocale]}>
            {children}
        </IntlProvider>
    );
};

export default LanguageLayout;
