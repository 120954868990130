import ShoppingCartImage from '@assets/icons/shoppingCart.svg';
import React, { FC } from 'react';
import { IHelper } from '@pages/Payment/types';
import { useIntl } from 'react-intl';
import { HelperDescription, HelperNumber, HelperShoppingCartWrapper, InlineBasket, PaymentHelper } from './styles';

const HelperAddingItems: FC<IHelper> = ({ goToNextStep }) => {
    const intl = useIntl();

    return (
        <PaymentHelper>
            <HelperDescription>
                <HelperNumber>1.</HelperNumber>
                {intl.formatMessage({
                    defaultMessage: 'Добавьте',
                    description: 'Добавьте в корзину один или несколько товаров',
                })}
                <HelperShoppingCartWrapper>
                    <ShoppingCartImage />
                </HelperShoppingCartWrapper>
                {intl.formatMessage({
                    defaultMessage: 'в корзину один или несколько товаров',
                    description: 'Добавьте в корзину один или несколько товаров',
                })}
            </HelperDescription>
            <HelperDescription>
                <HelperNumber>2.</HelperNumber>
                {intl.formatMessage({ defaultMessage: 'Перейдите в корзину, кликнув на' })}
                <InlineBasket onClick={goToNextStep} />
            </HelperDescription>
        </PaymentHelper>
    );
};

export default HelperAddingItems;
