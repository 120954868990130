import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getPaymentMethodsRequest } from '@store/PaymentMethods/http';
import { IPaymentIntegration, IPaymentMethod } from '@store/PaymentMethods/types';
import { Nullable } from '@utils/utilityTypes';
import createAsyncThunkWithErrorHandler from '@utils/helpers/createAsyncThunkWithErrorHandler';

export const fetchPaymentMethods = createAsyncThunkWithErrorHandler('paymentMethods/getAll', async () => {
    const { data } = await getPaymentMethodsRequest();
    return data;
});

export const paymentMethodsAdapter = createEntityAdapter<IPaymentMethod>();

interface IPaymentMethodsState {
    isLoading: boolean;
    metaData: {
        totalCount: number;
        pageSize: number;
        page: number;
    };
    paymentIntegrations: Nullable<IPaymentIntegration[]>;
}

const initialState = paymentMethodsAdapter.getInitialState({
    isLoading: true,
    metaData: {
        totalCount: 0,
        pageSize: 0,
        page: 0,
    },
    paymentIntegrations: null,
} as IPaymentMethodsState);

const paymentMethods = createSlice({
    name: 'paymentMethods',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPaymentMethods.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchPaymentMethods.fulfilled, (state, { payload }) => {
            paymentMethodsAdapter.setAll(state, payload.data);
            state.paymentIntegrations = payload.extendedData[0].data;
            state.isLoading = false;
        });
        builder.addCase(fetchPaymentMethods.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export default paymentMethods.reducer;
