import React, { FC, useState } from 'react';
import {
    ButtonAddToBasket,
    Content,
    Description,
    Image,
    ImageButton,
    MobileTitle,
    Price,
    ProductWrapper,
    Title,
} from '@pages/Payment/components/ProductItem/styles';
import IconShoppingCard from '@assets/icons/shoppingCart.svg';
import { convertCurrencyToShow, getImageURL } from '@utils/helpers';
import { IGood } from '@store/Good/types';
import { useAppDispatch } from '@store';
import { addGoodToCart } from '@store/Cart';
import { useSelector } from 'react-redux';
import { getCountItemsById } from '@store/Cart/selectors';
import LoadingSpinner from '@components/LoadingSpinner';
import ym from 'react-yandex-metrika';
import { enumGoalsYandexMetrika, enumMethodsYandexMetrika } from '@utils/constants';

interface IProductItem {
    product: IGood;
    showNotificationAdding: () => void;
}

const ProductItem: FC<IProductItem> = ({ product, showNotificationAdding }) => {
    const dispatch = useAppDispatch();
    const countInCart = useSelector(getCountItemsById(product.id));

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const onClickAddToBasket = async () => {
        ym(enumMethodsYandexMetrika.reachGoal, enumGoalsYandexMetrika.BASKET_ADD);
        const data = await dispatch(
            addGoodToCart({
                goodId: product.id,
                count: countInCart + 1,
            }),
        );
        // @ts-ignore
        if (data?.payload && !data?.error) {
            showNotificationAdding();
        }
    };

    return (
        <ProductWrapper>
            <ImageButton onClick={onClickAddToBasket} type="button">
                <Image
                    isLoaded={isImageLoaded}
                    src={getImageURL(product.imagePath)}
                    onLoad={() => setIsImageLoaded(true)}
                    alt={product.title}
                />
                {!isImageLoaded && <LoadingSpinner />}
                <MobileTitle>{product.title}</MobileTitle>
            </ImageButton>
            <Content>
                <Description>
                    <Title>{product.title}</Title>
                    <Price>{convertCurrencyToShow(product.price)}</Price>
                </Description>
                <ButtonAddToBasket onClick={onClickAddToBasket} type="button">
                    <IconShoppingCard />
                </ButtonAddToBasket>
            </Content>
        </ProductWrapper>
    );
};

export default React.memo(ProductItem);
