import styled, { keyframes } from 'styled-components';
import decorations from '@theme/decorations';

export const SkeletonWrapper = styled.span`
    display: flex;
    overflow: hidden;
`;

const skeletonAnimation = keyframes`
    from {
      background-position: -200px 0;
    }
    to {
      background-position: calc(200px + 100%) 0;
    }
`;

interface ISkeletonInnerProps {
    width?: number;
    height?: number;
    circle: boolean;
    borderRadius: keyof typeof decorations.borderRadius;
}

export const SkeletonBody = styled.span<ISkeletonInnerProps>`
    background-color: #eee;
    background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: ${({ theme, circle, borderRadius }) =>
        circle ? '50%' : `${theme.decorations.borderRadius[borderRadius]}px`};
    display: inline-block;
    line-height: 1;
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    height: ${({ height }) => (height ? `${height}px` : '100%')};
    animation: ${skeletonAnimation} 1.2s ease-in-out infinite;
`;
