import styled, { createGlobalStyle } from 'styled-components';
import { COLOR_BRIGHTNESS } from '@theme/utils';
import ShortLogoImage from '@assets/icons/shortLogo.svg';

export const OverflowHidden = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
`;

export const ContainerInner = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden auto;
    align-items: flex-start;
    padding: 55px 0;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 15px 0;
    }
`;

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.darkest(COLOR_BRIGHTNESS.MEDIUM)};
`;

export const Content = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    align-items: normal;
    margin: auto;
    max-width: calc(100vw - 30px); //Full width - container paddings 15px
    overflow: auto;
    position: relative;
    background-color: ${({ theme }) => theme.colors.white()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow?.base};
    overflow: hidden;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
`;

export const CloseButton = styled.button`
    position: absolute;
    display: flex;
    top: 22px;
    right: 22px;
    z-index: 1000;

    &:hover {
        path {
            stroke: ${({ theme }) => theme.colors.grey1()};
        }
    }

    &:active,
    &:focus {
        path {
            stroke: ${({ theme }) => theme.colors.dark3()};
        }
    }
`;

export const ModalInner = styled.div`
    padding: 50px 78px;
    width: 498px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 50px 38px;
        width: 420px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
        padding: 40px 24px 36px 24px;
    }
`;

export const Decoration = styled.div`
    position: relative;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: flex-start;
    flex-direction: column;
    width: 362px;
    background: ${({ theme }) => theme.colors.base()};
    min-height: 300px;
    padding: 56px 0;
    overflow: hidden;
    @media ${({ theme }) => theme.breakpoints.ld} {
        width: 278px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        display: none;
    }
`;

export const LogoWrapper = styled.div`
    transform: scale(0.85);
`;

export const ShortLogo = styled(ShortLogoImage)`
    margin-top: 132px;
    position: relative;
    z-index: 2;
`;

export const BackgroundGreyLogo = styled(ShortLogoImage)`
    position: absolute;
    transform: scale(2.9);
    transform-origin: 50% 0 0;
    top: 174px;

    path {
        stroke: ${({ theme }) => theme.colors.white()};
        fill: ${({ theme }) => theme.colors.white()};
        opacity: 0.03;
    }
    z-index: 1;
`;
