import React from 'react';
import {
    SkeletonButton,
    SkeletonImage,
    SkeletonPrice,
    SkeletonTitle,
    SkeletonWrapper,
} from '@pages/Payment/components/SkeletonProductItem/styles';
import { Content, Description } from '@pages/Payment/components/ProductItem/styles';

const SkeletonProductItem = () => (
    <SkeletonWrapper>
        <SkeletonImage />
        <Content>
            <Description>
                <SkeletonTitle />
                <SkeletonPrice />
            </Description>
            <SkeletonButton />
        </Content>
    </SkeletonWrapper>
);

export default SkeletonProductItem;
