import { RootState } from '@store';

export const getIsVisibleHeader = (state: RootState) => state.system.isVisibleHeader;

export const getTypeEnvironment = (state: RootState) => state.system.typeEnvironment;

export const getErrorCode = (state: RootState) => state.system.errorCode;

export const getLanguage = (state: RootState) => state.system.language;

export const getShopId = (state: RootState) => state.system.shopId;
