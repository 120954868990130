import React, { FC } from 'react';
import Portal from '@components/Portal';
import useClickOutside from '@hooks/useClickOutside';
import CloseIcon from '@assets/icons/closeSquare.svg';
import {
    Backdrop,
    BackgroundGreyLogo,
    CloseButton,
    Container,
    ContainerInner,
    Content,
    Decoration,
    LogoWrapper,
    ModalInner,
    OverflowHidden,
    ShortLogo,
} from '@components/Modal/styles';
import { logoLocale } from '@pages/Main/components/AppBanner';
import { useSelector } from 'react-redux';
import { getLanguage } from '@store/System/selectors';

interface IModalProps {
    isOpen: boolean;
    className?: string;
    closeModal: () => void;
}

const Modal: FC<IModalProps> = ({ children, isOpen, closeModal, className }) => {
    const contentRef = useClickOutside(closeModal);
    const language = useSelector(getLanguage);

    if (!isOpen) return null;

    const Logo = logoLocale[language];

    return (
        <Portal>
            <OverflowHidden />
            <Container>
                <ContainerInner>
                    <Backdrop />
                    <Content ref={contentRef}>
                        <CloseButton onClick={closeModal}>
                            <CloseIcon />
                        </CloseButton>
                        <Decoration>
                            <LogoWrapper>
                                <Logo />
                            </LogoWrapper>
                            <ShortLogo />
                            <BackgroundGreyLogo />
                        </Decoration>
                        <ModalInner className={className}>{children}</ModalInner>
                    </Content>
                </ContainerInner>
            </Container>
        </Portal>
    );
};

export default Modal;
