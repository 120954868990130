import styled from 'styled-components';
import ButtonBasket from '@pages/Payment/components/ButtonBasket';

export const PaymentHelper = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    flex-direction: column;
    grid-row-gap: 20px;

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row-gap: 10px;
    }
`;

export const HelperDescription = styled.div`
    ${({ theme }) => theme.mixins.getTypography('regular20')}
    color: ${({ theme }) => theme.colors.grey1()};

    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('regular16')}
    }
`;

export const HelperShoppingCartWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    display: inline-flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.base()};
    flex-shrink: 0;
    margin: 0 6px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 30px;
        height: 30px;
    }
`;

export const HelperNumber = styled.span`
    margin-right: 8px;
`;

export const InlineBasket = styled(ButtonBasket)`
    display: inline-flex;
    margin-left: 8px;
`;
