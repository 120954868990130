import React, { FC } from 'react';
import ShoppingCartImage from '@assets/icons/shoppingCart.svg';
import { useSelector } from 'react-redux';
import { getCountAllItems } from '@store/Cart/selectors';
import { ButtonBasketWrapper, CountBasketItems, enumSizeBasket } from './styles';

interface IButtonBasket {
    size?: enumSizeBasket;
    className?: string;
    onClick?: () => void;
}

const ButtonBasket: FC<IButtonBasket> = ({ onClick, className, size = enumSizeBasket.SMALL }) => {
    const totalCount: number = useSelector(getCountAllItems) as any;

    return (
        <ButtonBasketWrapper
            onClick={totalCount > 0 ? onClick : () => {}}
            isSmall={size === enumSizeBasket.SMALL}
            className={className}
        >
            <ShoppingCartImage />
            <CountBasketItems>{totalCount}</CountBasketItems>
        </ButtonBasketWrapper>
    );
};

export default ButtonBasket;
