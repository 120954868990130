import yup from '@utils/validations';
import { ORDER_FIELDS } from '@store/Order/types';
import { IntlShape } from 'react-intl';

export const paymentPrivateSchema = (intl: IntlShape) =>
    yup.object().shape({
        [ORDER_FIELDS.shopPaymentId]: yup.string().required(),
        [ORDER_FIELDS.contactFio]: yup.string().required(),
        [ORDER_FIELDS.contactPhone]: yup.string().isValidPhone(intl).required(),
        [ORDER_FIELDS.contactEmail]: yup.string().email().required(),
        consentProcessingPersonalData: yup.bool().oneOf([true]),
    });

export const paymentLegalSchema = (intl: IntlShape) =>
    yup.object().shape({
        [ORDER_FIELDS.shopPaymentId]: yup.string().required(),
        [ORDER_FIELDS.contactPhone]: yup.string().isValidPhone(intl).required(),
        [ORDER_FIELDS.contactEmail]: yup.string().email().required(),
        [ORDER_FIELDS.paymentInfo]: yup.object().shape({
            [ORDER_FIELDS.vatNumber]: yup.string().isValidInn(intl).required(),
            [ORDER_FIELDS.registrationAddress]: yup.string().required(),
            [ORDER_FIELDS.name]: yup.string().required(),
        }),
        consentProcessingPersonalData: yup.bool().oneOf([true]),
    });
