import React, { FC, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import {
    BasketContent,
    BasketHeader,
    BasketItemsContent,
    BasketItemsFooter,
    BasketItemsList,
    BasketItemsWrapper,
    BasketTitle,
    BasketWrapperForm,
    ButtonBackToCatalog,
    ButtonCreateOrder,
    TotalLabel,
    TotalValue,
    TotalWrapper,
} from '@pages/Payment/components/Basket/styles';
import ArrowLeft from '@assets/icons/arrowLeft.svg';
import ShopLogo from '@assets/icons/shopLogo.svg';
import { useSelector } from 'react-redux';
import { getAllGoods, getCartId, getTotalAmount } from '@store/Cart/selectors';
import BasketItem from '@pages/Payment/components/BasketItem';
import { convertCurrencyToShow } from '@utils/helpers';
import ContactInfo from '@pages/Payment/components/ContactInfo';
import useValidationSchema from '@hooks/useValidationSchema';
import { paymentLegalSchema, paymentPrivateSchema } from '@pages/Payment/components/validations';
import PaymentMethods from '@pages/Payment/components/PaymentMethods';
import { IOrder, IOrderResponse, ORDER_FIELDS } from '@store/Order/types';
import { createOrder } from '@store/Order';
import { useAppDispatch } from '@store';
import useLoadingSubmit from '@hooks/useLoadingSubmit';
import { useTablet } from '@hooks/useMedia';
import { removePlusAndGapsToPhone } from '@utils/formatters';
import { useHistory } from 'react-router-dom';
import { IStep } from '@pages/Payment/types';
import { Routes } from '@pages/constants';
import { getPaymentMethods } from '@store/PaymentMethods/selectors';
import ym from 'react-yandex-metrika';
import { enumGoalsYandexMetrika, enumMethodsYandexMetrika } from '@utils/constants';
import { useIntl } from 'react-intl';

const Basket: FC<IStep> = ({ goToPrevStep, currentTypePayment, typePaymentKeep }) => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const isTablet = useTablet();
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();

    const validationPrivate = useValidationSchema(paymentPrivateSchema(intl));
    const validationLegal = useValidationSchema(paymentLegalSchema(intl));

    const [isLegal, setIsLegal] = useState(false);

    const basketItems = useSelector(getAllGoods);
    const totalAmount = useSelector(getTotalAmount);
    const cartId: number = useSelector(getCartId) as any;
    const arrPaymentMethods = useSelector(getPaymentMethods);

    const currentExternal = typePaymentKeep[currentTypePayment].external;

    const paymentsMethodsToCurrentTypePayment = useMemo(
        () => arrPaymentMethods.filter((paymentMethod) => currentExternal.includes(paymentMethod.externalId)),
        [arrPaymentMethods, currentExternal],
    );

    const onSubmit = async (formValues: IOrder) => {
        ym(enumMethodsYandexMetrika.reachGoal, enumGoalsYandexMetrika.CREATE_ORDER);
        toggleLoadingSubmit();
        const data = await dispatch(
            createOrder({
                ...formValues,
                [ORDER_FIELDS.contactFio]: formValues[ORDER_FIELDS.contactFio] || '',
                [ORDER_FIELDS.shopPaymentId]: Number(formValues[ORDER_FIELDS.shopPaymentId]),
                [ORDER_FIELDS.cartId]: cartId,
                [ORDER_FIELDS.contactPhone]: removePlusAndGapsToPhone(formValues[ORDER_FIELDS.contactPhone]),
                [ORDER_FIELDS.paymentInfo]: isLegal ? formValues[ORDER_FIELDS.paymentInfo] : undefined,
            }),
        );

        // @ts-ignore
        if (data?.payload && !data?.error && data?.payload?.data?.id) {
            // @ts-ignore
            const order: IOrderResponse = data.payload.data as any;
            history.replace(`${Routes.order}/${order.id}`);
        }
        toggleLoadingSubmit();
    };

    const initialValuesForm = {
        [ORDER_FIELDS.description]: '',
        [ORDER_FIELDS.consentProcessingPersonalData]: false,
        [ORDER_FIELDS.shopPaymentId]:
            paymentsMethodsToCurrentTypePayment.length === 1
                ? String(paymentsMethodsToCurrentTypePayment[0].id)
                : undefined,
    };

    return (
        <Form
            initialValues={initialValuesForm}
            onSubmit={onSubmit}
            validate={isLegal ? validationLegal : validationPrivate}
        >
            {({ handleSubmit, valid }) => (
                <BasketWrapperForm>
                    <BasketContent>
                        <BasketHeader>
                            <ButtonBackToCatalog onClick={goToPrevStep} icon={<ArrowLeft />} type="link">
                                {isTablet
                                    ? intl.formatMessage({ defaultMessage: 'В каталог' })
                                    : intl.formatMessage({ defaultMessage: 'Вернуться в каталог' })}
                            </ButtonBackToCatalog>
                            <ShopLogo />
                        </BasketHeader>
                        <BasketTitle>{intl.formatMessage({ defaultMessage: 'Способ оплаты' })}</BasketTitle>
                        <PaymentMethods
                            setIsLegal={setIsLegal}
                            paymentsMethodsToCurrentTypePayment={paymentsMethodsToCurrentTypePayment}
                        />
                        <BasketTitle>{intl.formatMessage({ defaultMessage: 'Контактная информация' })}</BasketTitle>
                        <ContactInfo isLegal={isLegal} />
                    </BasketContent>
                    <BasketItemsWrapper>
                        <BasketItemsContent>
                            <BasketTitle>{intl.formatMessage({ defaultMessage: 'Список покупок' })}</BasketTitle>
                            <BasketItemsList>
                                {basketItems?.map((basketItem) => (
                                    <BasketItem key={basketItem.id} good={basketItem} />
                                ))}
                            </BasketItemsList>
                        </BasketItemsContent>
                        <BasketItemsFooter>
                            <TotalWrapper>
                                <TotalLabel>{intl.formatMessage({ defaultMessage: 'Итого:' })}</TotalLabel>
                                <TotalValue>{totalAmount && convertCurrencyToShow(totalAmount)}</TotalValue>
                            </TotalWrapper>
                            <ButtonCreateOrder
                                isLoading={isLoadingSubmit}
                                disabled={!valid}
                                type="dark"
                                onClick={handleSubmit}
                            >
                                {intl.formatMessage({ defaultMessage: 'Сформировать счёт' })}
                            </ButtonCreateOrder>
                        </BasketItemsFooter>
                    </BasketItemsWrapper>
                </BasketWrapperForm>
            )}
        </Form>
    );
};

export default Basket;
