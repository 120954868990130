import React, { FC } from 'react';
import { INTEGRATION_FIELDS } from '@store/Claim/types';
import { useIntl } from 'react-intl';
import FormField from '@components/FormField';
import { InputTypeEnum } from '@components/Input';
import { phoneFieldFormatters } from '@utils/formatters';

interface IGeneratorInputsForm {
    properties: { [key: string]: { [INTEGRATION_FIELDS.type]: string } };
}

const GeneratorInputsForm: FC<IGeneratorInputsForm> = ({ properties }) => {
    const intl = useIntl();

    const getFieldAttributes = (nameField: string, typeField: string) => {
        const inputType = typeField === 'string' ? InputTypeEnum.text : InputTypeEnum.numeric;

        switch (nameField) {
            case 'fullName': {
                return {
                    placeholder: intl.formatMessage({ defaultMessage: 'Заполните поле' }),
                    label: intl.formatMessage({ defaultMessage: 'ФИО' }),
                    typeInput: inputType,
                    name: nameField,
                };
            }
            case 'phone': {
                return {
                    placeholder: '+7 XXX XXX XX XX',
                    mask: '+7 999 999 99 99',
                    label: intl.formatMessage({ defaultMessage: 'Телефон' }),
                    typeInput: inputType,
                    name: nameField,
                    ...phoneFieldFormatters,
                };
            }
            case 'email': {
                return {
                    placeholder: intl.formatMessage({ defaultMessage: 'Укажите электронную почту' }),
                    label: 'Email',
                    typeInput: inputType,
                    name: nameField,
                };
            }
            case 'vatNumber': {
                return {
                    placeholder: intl.formatMessage({ defaultMessage: 'Укажите ИНН организации или ИП' }),
                    label: intl.formatMessage({ defaultMessage: 'ИНН' }),
                    typeInput: InputTypeEnum.numeric,
                    name: nameField,
                    maxLength: 12,
                };
            }
            case 'registrationAddress': {
                return {
                    placeholder: intl.formatMessage({ defaultMessage: 'Индекс, город, улица, офис...' }),
                    label: intl.formatMessage({ defaultMessage: 'Юридический адрес' }),
                    name: nameField,
                };
            }
            default: {
                return {
                    placeholder: nameField,
                    label: nameField,
                    typeInput: inputType,
                    name: nameField,
                };
            }
        }
    };

    return (
        <>
            {Object.entries(properties)?.map((property) => {
                const fieldName = property?.[0];
                if (fieldName !== 'body') {
                    const fieldAttributes = getFieldAttributes(fieldName, 'string');
                    return <FormField key={fieldName} {...fieldAttributes} />;
                }
                return null;
            })}
        </>
    );
};

export default GeneratorInputsForm;
