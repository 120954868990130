import React, { FC } from 'react';
import { HelperDescription, HelperNumber, PaymentHelper } from '@pages/Payment/components/HelperAddingItems/styles';
import { useIntl } from 'react-intl';

interface IHelperDetailPayment {
    isPaymentCard: boolean;
}

const HelperDetailPayment: FC<IHelperDetailPayment> = ({ isPaymentCard }) => {
    const intl = useIntl();
    const helperDescriptions = [
        {
            id: 1,
            value: isPaymentCard ? (
                <>
                    {intl.formatMessage({
                        defaultMessage: 'Перейдите на платёжную страницу и проведите тестовую оплату.',
                    })}
                    {/* todo пока не реализуем, позже сюда передать ссылку на чек ч-з родительский компонент */}
                    {/* Посмотреть оформленный чек вы можете, нажав на кнопку ниже <CheckLink> «Посмотреть чек»</CheckLink> */}
                </>
            ) : (
                intl.formatMessage({
                    defaultMessage:
                        'Вы можете скачать оформленный счёт или почитать подробнее о возможностях получения заказа до фактической оплаты счёта',
                })
            ),
        },
        {
            id: 2,
            value: intl.formatMessage({
                defaultMessage: 'Тестовый электронный чек будет отправлен на указанный адрес эл. почты',
            }),
        },
    ];
    return (
        <PaymentHelper>
            {helperDescriptions.map((helperRow) => (
                <HelperDescription key={helperRow.id}>
                    <HelperNumber>{helperRow.id}.</HelperNumber>
                    {helperRow.value}
                </HelperDescription>
            ))}
        </PaymentHelper>
    );
};

export default HelperDetailPayment;
