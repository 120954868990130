import styled, { css } from 'styled-components';

export enum enumSizeBasket {
    SMALL = 'small',
    LARGE = 'large',
}

const stylesLargeCounter = css`
    width: 33px;
    height: 33px;
    top: -28px;
    right: -28px;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 24px;
        height: 24px;
        right: -20px;
        top: -20px;
    }
`;

const stylesSmallCounter = css`
    top: -18px;
    right: -18px;
    width: 23px;
    height: 23px;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 17px;
        height: 17px;
        right: -13px;
        top: -13px;
    }
`;

export const CountBasketItems = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    position: absolute;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.yellow()};
    color: ${({ theme }) => theme.colors.base()};
`;

interface IBasketWrapper {
    isSmall: boolean;
}

export const ButtonBasketWrapper = styled.button<IBasketWrapper>`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    display: inline-flex;
    position: relative;
    svg {
        transform: ${({ isSmall }) => (isSmall ? 'scale(1.9)' : 'scale(2.7)')};
        path {
            fill: ${({ theme }) => theme.colors.base()};
        }
    }

    &:active,
    &:focus {
        ${CountBasketItems} {
            background: ${({ theme }) => theme.colors.activeYellow()};
        }
    }

    ${CountBasketItems} {
        ${({ theme, isSmall }) =>
            isSmall ? theme.mixins.getTypography('regular14') : theme.mixins.getTypography('regular20')}
        ${({ isSmall }) => (isSmall ? stylesSmallCounter : stylesLargeCounter)};
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        svg {
            transform: ${({ isSmall }) => (isSmall ? 'scale(1.4)' : 'scale(2)')};
        }

        ${CountBasketItems} {
            ${({ theme, isSmall }) =>
                isSmall ? theme.mixins.getTypography('regular10') : theme.mixins.getTypography('regular16')}
            ${({ isSmall }) => (isSmall ? stylesSmallCounter : stylesLargeCounter)};
        }
    }
`;
