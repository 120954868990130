import React from 'react';
import { PaymentButtonsWrapper, PaymentMethodWrapper, PaymentTitle } from '@pages/Main/components/PaymentMethod/styles';
import PaymentButton from '@pages/Main/components/PaymentButton';
import CardsImage from '@assets/icons/payCards.svg';
import PhoneImage from '@assets/icons/payPhone.svg';
import WindowImage from '@assets/icons/payWindow.svg';
import InternetBankImage from '@assets/icons/payInternetBank.svg';
import { Routes } from '@pages/constants';
import { useIntl } from 'react-intl';

const PaymentMethod = () => {
    const intl = useIntl();

    const paymentMethods = [
        {
            title: intl.formatMessage({ defaultMessage: 'Оплата банковской картой в интернет-магазине' }),
            image: CardsImage,
            route: Routes.order + Routes.paymentCard,
        },
        {
            title: intl.formatMessage({ defaultMessage: 'Оплата по счёту для организаций и ИП' }),
            image: PhoneImage,
            route: Routes.order + Routes.paymentAccount,
        },
        {
            title: intl.formatMessage({
                defaultMessage: 'Покупка в интернет-магазине с выбором способа оплаты',
            }),
            image: WindowImage,
            route: Routes.order + Routes.paymentAny,
        },
        {
            title: intl.formatMessage({
                defaultMessage: 'Оплата через интернет-банк',
            }),
            image: InternetBankImage,
            route: Routes.order + Routes.paymentInternetBank,
        },
    ];

    return (
        <PaymentMethodWrapper>
            <PaymentTitle>
                {intl.formatMessage({ defaultMessage: 'Выберите интересующий вас способ оплаты для демонстрации' })}
            </PaymentTitle>
            <PaymentButtonsWrapper>
                {paymentMethods.map((payment) => (
                    <PaymentButton
                        routePayment={payment.route}
                        Image={payment.image}
                        title={payment.title}
                        key={payment.title}
                    />
                ))}
            </PaymentButtonsWrapper>
        </PaymentMethodWrapper>
    );
};

export default PaymentMethod;
