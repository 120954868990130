import React, { FC, InputHTMLAttributes, ReactNode } from 'react';
import useFocusField from '@hooks/useFocusField';
import InputIconContainer from '@components/Input/components/InputIconContainer';
import { InputContainer, InputField, InputLabel, InputWrapper, MaskField } from '@components/Input/styles';

export enum InputTypeEnum {
    text = 'text',
    numeric = 'numeric',
}

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string | React.ReactNode;
    disabled?: boolean;
    hasError?: boolean;
    className?: string;
    mask?: string;
    icon?: ReactNode;
    onIconClick?: () => void;
    iconIsDisabled?: boolean;
    onChange?: any;
    typeInput?: InputTypeEnum;
    maxValue?: number;
    isRounded?: boolean;
    maxLength?: number;
}

const Input: FC<IInputProps> = ({
    maxLength,
    isRounded = true,
    typeInput = InputTypeEnum.text,
    maxValue,
    placeholder,
    disabled = false,
    hasError = false,
    label = '',
    className,
    mask,
    icon,
    onIconClick,
    iconIsDisabled,
    onFocus,
    onBlur,
    ...props
}) => {
    const { inFocus, onFocusHandler, onBlurHandler } = useFocusField({ isFocus: false, onFocus, onBlur });
    // only use the input mask if there is a value -- this prevents issues with Chrome auto-fill
    const inputMask = props.value ? mask : null;

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (typeInput === InputTypeEnum.numeric) {
            if (maxValue) {
                // @ts-ignore
                if (maxValue >= Number(e.target.value)) {
                    props.onChange(e.target.value.replace(/[^0-9]/g, ''));
                }
            } else {
                props.onChange(e.target.value.replace(/[^0-9]/g, ''));
            }
        } else {
            props.onChange(e.target.value);
        }
    };

    return (
        <InputWrapper isRounded={isRounded} hasError={hasError} disabled={disabled} className={className}>
            {label && (
                <InputLabel hasError={hasError} inFocus={inFocus}>
                    {label}
                </InputLabel>
            )}
            <InputContainer isRounded={isRounded} hasError={hasError} hasIcon={!!icon} inFocus={inFocus}>
                {mask ? (
                    <MaskField
                        $isRounded={isRounded}
                        // @ts-ignore
                        mask={inputMask}
                        placeholder={placeholder}
                        disabled={disabled}
                        onFocus={onFocusHandler}
                        onBlur={onBlurHandler}
                        {...props}
                    />
                ) : (
                    <InputField
                        maxLength={maxLength}
                        $isRounded={isRounded}
                        placeholder={placeholder}
                        disabled={disabled}
                        onFocus={onFocusHandler}
                        onBlur={onBlurHandler}
                        {...props}
                        onChange={onChangeHandler}
                    />
                )}
                {icon && (
                    <InputIconContainer onClick={onIconClick} iconIsDisabled={iconIsDisabled}>
                        {icon}
                    </InputIconContainer>
                )}
            </InputContainer>
        </InputWrapper>
    );
};

export default React.memo(Input);
