export enum enumOrderStatus {
    new = 'new',
    paid = 'paid',
}

export enum ORDER_FIELDS {
    id = 'id',
    description = 'description',
    cartId = 'cartId',
    shopPaymentId = 'shopPaymentId',
    paymentInfo = 'paymentInfo',
    contactFio = 'contactFio',
    contactPhone = 'contactPhone',
    contactEmail = 'contactEmail',
    vatNumber = 'vatNumber',
    customerId = 'customerId',
    createdAt = 'createdAt',
    shopId = 'shopId',
    shopClientId = 'shopClientId',
    amount = 'amount',
    type = 'type',
    uuid = 'uuid',
    status = 'status',
    consentProcessingPersonalData = 'consentProcessingPersonalData',
    registrationAddress = 'registrationAddress',
    name = 'name',
}

export interface IOrder {
    [ORDER_FIELDS.description]: string;
    [ORDER_FIELDS.cartId]: number;
    [ORDER_FIELDS.shopPaymentId]: number;
    [ORDER_FIELDS.paymentInfo]?: {
        // legal fields
        [ORDER_FIELDS.vatNumber]: string;
        [ORDER_FIELDS.registrationAddress]: string;
        [ORDER_FIELDS.name]: string;
    };
    [ORDER_FIELDS.contactFio]: string;
    [ORDER_FIELDS.contactPhone]: string;
    [ORDER_FIELDS.contactEmail]: string;
}

export interface IOrderResponse extends IOrder {
    [ORDER_FIELDS.id]: number;
    [ORDER_FIELDS.customerId]: number;
    [ORDER_FIELDS.status]: string;
    [ORDER_FIELDS.createdAt]: string;
    [ORDER_FIELDS.shopId]: number;
    [ORDER_FIELDS.shopClientId]: number;
    [ORDER_FIELDS.amount]: number;
    [ORDER_FIELDS.type]: string;
    [ORDER_FIELDS.uuid]: string;
}
