// @ts-ignore
import { AsyncThunkPayloadCreator, GetThunkAPI } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '@store';
import { setErrorCode } from '@store/System';
import { networkErrorResponse } from '@utils/constants';
import { enumErrorCode } from '../../layouts/Main/types';

function commonThunkHandler<A, B, C extends { state: RootState; dispatch: AppDispatch }>(
    callback: AsyncThunkPayloadCreator<A, B, C>,
): AsyncThunkPayloadCreator<A, B, C> {
    // eslint-disable-next-line no-shadow,@typescript-eslint/no-unused-vars,no-unused-vars,@typescript-eslint/no-shadow
    return <A, B, C>(args: B, thunkAPI: GetThunkAPI<C>, ...rest: any[]) =>
        // @ts-ignore
        callback(args, thunkAPI, ...rest).catch((error) => {
            const isNetworkError = error.toString() === networkErrorResponse;
            if (isNetworkError) {
                thunkAPI.dispatch(setErrorCode(enumErrorCode.internetConnection));
                return thunkAPI.rejectWithValue(error.response.data);
            }
            thunkAPI.dispatch(setErrorCode(enumErrorCode.XHR));
            return thunkAPI.rejectWithValue(error.response.data);
        });
}

export default commonThunkHandler;
