import styled from 'styled-components';
import Typography from '@components/Typography';
import ArrowDownIcon from '@assets/icons/arrowDown.svg';

export const EnvironmentWrapper = styled.div`
    position: relative;
    ${({ theme }) => theme.mixins.getTypography('bold14')}
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 17px;

    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 48px;
    }
`;

export const SelectedEnvironmentButton = styled.button`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 10px;
`;

export const EnvironmentIconWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;

export const SelectedEnvironmentName = styled(Typography).attrs({ variant: 'regular14' })``;

interface IArrowDown {
    $isOpen: boolean;
}

export const ArrowDown = styled(ArrowDownIcon)<IArrowDown>`
    margin-left: -2px;
    transform: ${({ $isOpen }) => ($isOpen ? 'rotateZ(180deg)' : 'none')};
    transition: ${({ theme }) => theme.decorations.transition?.base};
`;

interface IEnvironmentList {
    isOpen: boolean;
}

export const EnvironmentList = styled.div<IEnvironmentList>`
    position: absolute;
    left: -12px;
    bottom: 34px;
    padding: ${({ isOpen }) => (isOpen ? '8px 0' : '0')};
    background: ${({ theme }) => theme.colors.white()};
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    max-height: ${({ isOpen }) => (isOpen ? '150px' : '0')};
    transition: ${({ theme }) => theme.decorations.transition?.base};
    overflow: hidden;
`;

export const EnvironmentListWrapper = styled.div`
    position: relative;
`;
