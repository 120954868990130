import styled from 'styled-components';
import Typography from '@components/Typography';
import ArrowRightImage from '@assets/icons/arrowRight.svg';

export const Decor = styled.div`
    width: 90px;
    height: 90px;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    position: absolute;
    right: -16px;
    bottom: -18px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 52px;
        height: 52px;
        right: -5px;
        bottom: -5px;
    }
`;

export const ImageWrapper = styled.div`
    flex: 0 0 auto;
    position: relative;
    z-index: 1;
    bottom: -5px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        transform: scale(0.6);
        transform-origin: 0 50% 0;
        max-width: 120px;
        flex-shrink: 1;
        margin-left: -10px;
    }
`;

export const DecorCircle = styled.div`
    background: ${({ theme }) => theme.colors.yellow()};
    width: 90px;
    height: 90px;
    border-radius: 50%;
    position: absolute;
    transition: ${({ theme }) => theme.decorations.transition?.secondary};
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 52px;
        height: 52px;
    }
`;

interface IPaymentButtonWrapper {
    isActiveDecorCircle: boolean;
}

export const PaymentButtonWrapper = styled.button<IPaymentButtonWrapper>`
    position: relative;
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    background: ${({ theme }) => theme.colors.grey4()};
    overflow: hidden;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    grid-column-gap: 18px;
    padding-right: 42px;

    //desktop - hover effect
    &:hover {
        ${DecorCircle} {
            transform: scale(30);
            background: ${({ theme }) => theme.colors.hoverYellow()};
        }
    }

    &:active,
    &:focus {
        ${DecorCircle} {
            background: ${({ theme }) => theme.colors.activeYellow()};
        }
    }

    //mobile - hover effect is none
    @media ${({ theme }) => theme.breakpoints.ld} {
        &:hover {
            ${DecorCircle} {
                transform: none;
            }
        }
    }

    //mobile - hover effect after click
    ${DecorCircle} {
        transform: ${({ isActiveDecorCircle }) => (isActiveDecorCircle ? `scale(30) !important` : 'none')};
    }

    @media ${({ theme }) => theme.breakpoints.xl} {
        justify-content: flex-start;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        height: 136px;
        padding-right: 20px;
    }
`;

export const ButtonTitle = styled(Typography).attrs({ variant: 'regular24' })`
    position: relative;
    z-index: 2;
    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.getTypography('regular14')}
    }
`;

export const ArrowRight = styled(ArrowRightImage)`
    position: relative;
    @media ${({ theme }) => theme.breakpoints.sm} {
        transform: scale(0.7);
    }
`;
