import styled from 'styled-components';
import Typography from '@components/Typography';

export const PaymentMethodsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    border: 1px solid ${({ theme }) => theme.colors.grey3()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    margin: 24px 0 46px 0;
    padding: 2px 36px 7px 36px;
    @media ${({ theme }) => theme.breakpoints.xl} {
        padding: 2px 24px 7px 24px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        margin: 18px 0 24px 0;
        padding: 0 16px 3px 16px;
    }
`;

export const PaymentMethodWrapper = styled.div`
    padding: 26px 0 20px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.grey3()};
    &:first-child {
        border: none;
    }
`;

export const PaymentMethodRadio = styled.div`
    width: 100%;
    @media ${({ theme }) => theme.breakpoints.md} {
        display: grid;
        grid-template-columns: 1fr;
    }
`;

export const PaymentMethodName = styled(Typography).attrs({ variant: 'medium16' })``;

export const PaymentMethodDescription = styled(Typography).attrs({ variant: 'regular14' })`
    margin-top: 10px;
    color: ${({ theme }) => theme.colors.grey1()};
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 8px;
    }
`;

export const PaymentHeader = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    @media ${({ theme }) => theme.breakpoints.md} {
        display: contents;
    }
`;

export const PayIconsWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 10px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row: 3;
        margin-top: 20px;
        flex-wrap: wrap;
        grid-gap: 10px;
    }
`;
