import React, { FC, ReactNode } from 'react';
import LoadingLogo from '@components/Button/components/LoadingLogo';
import { ButtonInner, ButtonWrapper, StyledButtonType } from '@components/Button/styles';
import { NavLink } from 'react-router-dom';

export type ButtonType = 'primary' | 'secondary' | 'dark' | 'link';

interface IButtonProps {
    type?: ButtonType;
    disabled?: boolean;
    icon?: ReactNode;
    onClick?: () => void;
    className?: string;
    htmlType?: ButtonHtmlType;
    href?: string;
    to?: string;
    isLoading?: boolean;
}

type ButtonHtmlType = 'submit' | 'reset' | 'button';

const getStyledButtonType = (buttonType: ButtonType, hasIcon: boolean): StyledButtonType =>
    buttonType === 'link' && hasIcon ? 'linkWithIcon' : buttonType;

const Button: FC<IButtonProps> = ({
    children,
    type = 'primary',
    disabled = false,
    icon,
    onClick,
    className,
    htmlType = 'button',
    href,
    to,
    isLoading = false,
}) => {
    const isLink = !!href;

    const getTypeButton = () => {
        if (isLink) {
            return 'a';
        }
        if (to) {
            return NavLink;
        }
        return 'button';
    };

    return (
        <ButtonWrapper
            as={getTypeButton()}
            to={to || undefined}
            disabled={disabled}
            $buttonType={getStyledButtonType(type, !!icon)}
            $iconOnly={!children}
            onClick={isLink || isLoading ? undefined : onClick}
            $hasIcon={!!icon}
            type={htmlType}
            className={className}
            href={href}
            target={isLink ? '__blank' : undefined}
        >
            {isLoading && <LoadingLogo type={type} />}
            <ButtonInner isLoading={isLoading} iconOnly={!children}>
                {icon && icon}
                {children}
            </ButtonInner>
        </ButtonWrapper>
    );
};
export default Button;
