import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { enumTypeEnvironment } from '@store/System/types';
import { Nullable } from '@utils/utilityTypes';
import { enumLanguage, localStorageLanguageKey } from '../../layouts/Main/types';

export const systemAdapter = createEntityAdapter();

interface IInitialState {
    isVisibleHeader: boolean;
    typeEnvironment: enumTypeEnvironment;
    errorCode: Nullable<string>;
    language: enumLanguage;
    shopId: Nullable<number>;
}

const initialState = systemAdapter.getInitialState({
    isVisibleHeader: true,
    typeEnvironment: enumTypeEnvironment.develop,
    errorCode: null,
    language: enumLanguage.ru,
    shopId: null,
} as IInitialState);

const system = createSlice({
    name: 'system',
    initialState,
    reducers: {
        setIsHeaderVisible: (state) => {
            state.isVisibleHeader = true;
        },
        setIsHeaderInvisible: (state) => {
            state.isVisibleHeader = false;
        },
        setEnvironmentType: (state, { payload }) => {
            state.typeEnvironment = payload;
        },
        setErrorCode: (state, { payload }) => {
            state.errorCode = payload;
        },
        clearErrorCode: (state) => {
            state.errorCode = null;
        },
        setLanguage: (state, { payload }) => {
            state.language = payload;
            localStorage.setItem(localStorageLanguageKey, payload);
        },
        setShopId: (state, { payload }) => {
            state.shopId = payload;
        },
    },
});

export const {
    setIsHeaderVisible,
    setIsHeaderInvisible,
    setEnvironmentType,
    setErrorCode,
    clearErrorCode,
    setLanguage,
    setShopId,
} = system.actions;

export default system.reducer;
