import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AuthRouter from '@pages/routes';
import { useAppDispatch } from '@store';
import { fetchUserProfile } from '@store/Auth';
import { useSelector } from 'react-redux';
import { getUser } from '@store/Auth/selectors';
import { fetchPaymentMethods } from '@store/PaymentMethods';
import { setEnvironmentType } from '@store/System';
import { getEnvironmentByURL } from '@utils/helpers';
import { fetchClaims } from '@store/Claim';
import LanguageLayout from '../layouts/LanguageLayout';

const Pages = () => {
    const dispatch = useAppDispatch();
    const user = useSelector(getUser);

    const { location } = document;

    useEffect(() => {
        dispatch(setEnvironmentType(getEnvironmentByURL(location.href)));
    }, [dispatch, location.href]);

    useEffect(() => {
        dispatch(fetchUserProfile());
    }, [dispatch]);

    useEffect(() => {
        if (user) {
            dispatch(fetchPaymentMethods());
            dispatch(fetchClaims());
        }
    }, [dispatch, user]);

    return (
        <BrowserRouter>
            <LanguageLayout>{user && <AuthRouter />} </LanguageLayout>
        </BrowserRouter>
    );
};

export default Pages;
