import styled from 'styled-components';
import Typography from '@components/Typography';
import Button from '@components/Button';

export const PaymentWrapper = styled.div`
    padding-bottom: 180px;

    @media ${({ theme }) => theme.breakpoints.xl} {
        padding: 0 36px 140px 36px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 0 16px 100px 16px;
    }
`;

export const PaymentHead = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    padding-bottom: 40px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey3()};
    margin-bottom: 60px;

    @media ${({ theme }) => theme.breakpoints.xl} {
        flex-direction: column-reverse;
        align-items: flex-start;
        grid-row-gap: 33px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row-gap: 30px;
        padding-bottom: 30px;
        margin-bottom: 25px;
    }
`;

export const PaymentTitle = styled(Typography).attrs({ variant: 'bold34' })`
    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('bold24')}
    }
`;

export const HeaderButtons = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 18px;
    @media ${({ theme }) => theme.breakpoints.xl} {
        flex-direction: row-reverse;
    }
`;

export const ButtonRepeatOrder = styled(Button)``;

export const ButtonBackToMain = styled(Button)``;

export const PaymentInfo = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    grid-column-gap: 126px;
    margin-bottom: 90px;

    @media ${({ theme }) => theme.breakpoints.xl} {
        flex-direction: column;
        grid-row-gap: 40px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 52px;
    }
`;
