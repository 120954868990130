import styled from 'styled-components';
import FormField from '@components/FormField';

export const InputsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    border: 1px solid ${({ theme }) => theme.colors.grey3()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    padding: 36px;
    margin-top: 24px;

    @media ${({ theme }) => theme.breakpoints.xl} {
        padding: 36px 24px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 100%;
        grid-row-gap: 10px;
        padding: 20px 16px;
    }
`;

interface ICheckboxConsent {
    isLegal: boolean;
}

export const CheckboxConsent = styled(FormField)<ICheckboxConsent>`
    padding-top: ${({ isLegal }) => (isLegal ? '0' : '24px')};
    grid-column: ${({ isLegal }) => (isLegal ? '1/3' : 'auto/auto')};
    max-width: ${({ isLegal }) => (isLegal ? '392px' : '100%')};

    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 0;
        grid-column: auto/auto;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        max-width: 100%;
    }
`;

export const LinkToPrivacy = styled.a`
    ${({ theme }) => theme.mixins.getTypography('regular12')}
    color: ${({ theme }) => theme.colors.grey1()};
`;

export const AddressField = styled(FormField)`
    grid-column: 1/3;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column: auto/auto;
    }
`;
