import styled from 'styled-components';
import Typography from '@components/Typography';
import Button from '@components/Button';

export const BasketWrapperForm = styled.form`
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    display: grid;
    grid-template-columns: 1fr 384px;
    overflow: hidden;
    @media ${({ theme }) => theme.breakpoints.xl} {
        grid-template-columns: 100%;
    }
`;

export const BasketContent = styled.div`
    background: ${({ theme }) => theme.colors.white()};
    padding: 40px 67px 70px 36px;
    @media ${({ theme }) => theme.breakpoints.xl} {
        padding: 40px 24px 70px 24px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 28px 10px 50px 10px;
    }
`;

export const BasketItemsWrapper = styled.div`
    background: ${({ theme }) => theme.colors.grey4()};
    padding: 46px 46px 52px 46px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 20px 10px 35px 10px;
    }
`;

export const BasketHeader = styled.div`
    padding-right: 130px;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    margin-bottom: 65px;
    @media ${({ theme }) => theme.breakpoints.xl} {
        position: relative;
        justify-content: center;
        padding: 0;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 36px;
    }
`;

export const BasketTitle = styled(Typography).attrs({ variant: 'bold24' })`
    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('bold20')}
    }
`;

export const AboutPayer = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.grey3()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    padding: 36px;
    margin-top: 24px;
    @media ${({ theme }) => theme.breakpoints.xl} {
        padding: 36px 24px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 20px 16px;
    }
`;

export const BasketItemsList = styled.div`
    padding: 40px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.grey3()};
    margin-top: 14px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
    @media ${({ theme }) => theme.breakpoints.xl} {
        grid-template-columns: repeat(3, minmax(240px, 292px));
        justify-content: space-between;
        grid-column-gap: 22px;
    }
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-template-columns: repeat(2, minmax(240px, 392px));
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 8px;
        padding: 18px 0 20px 0;
        grid-template-columns: 1fr;
        grid-row-gap: 18px;
    }
`;

export const TotalWrapper = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.grey3()};
    padding-top: 14px;
    margin-bottom: 24px;
    ${({ theme }) => theme.mixins.flexStart};
    justify-content: space-between;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding-top: 8px;
        margin-bottom: 28px;
    }
`;

export const TotalLabel = styled(Typography).attrs({ variant: 'medium16' })``;

export const TotalValue = styled(Typography).attrs({ variant: 'regular16' })``;

export const BasketItemsContent = styled.div``;

export const BasketItemsFooter = styled.div``;

export const ButtonBackToCatalog = styled(Button)`
    @media ${({ theme }) => theme.breakpoints.xl} {
        position: absolute;
        left: 0;
        top: 0;
    }
`;

export const ButtonCreateOrder = styled(Button)`
    @media ${({ theme }) => theme.breakpoints.xl} {
        margin: 0 auto;
    }
`;
