import React from 'react';
import SkeletonProductItem from '@pages/Payment/components/SkeletonProductItem';

const SkeletonProducts = () => {
    const countProducts = 7;
    const arraySkeletonProducts = new Array(countProducts).fill(SkeletonProductItem);
    return (
        <>
            {arraySkeletonProducts.map((SkeletonItem, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SkeletonItem key={index} />
            ))}
        </>
    );
};

export default SkeletonProducts;
