import styled from 'styled-components';
import Typography from '@components/Typography';

interface IFlagWrapper {
    isSelected: boolean;
}

export const FlagWrapper = styled.div<IFlagWrapper>`
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.grey4()};
    ${({ theme }) => theme.mixins.flexCenterCenter};
    svg {
        transform: scale(0.7);
    }
    border: 1px solid ${({ theme, isSelected }) => (isSelected ? theme.colors.yellow() : 'none')};
`;

export const LanguageTitle = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey()};
`;

export const LanguageItemButton = styled.button`
    padding: 6px 14px;
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 10px;
    &:hover {
        ${FlagWrapper} {
            background: #e3e4e8;
        }
    }
`;
