import styled from 'styled-components';
import Typography from '@components/Typography';
import PhoneImage from '@assets/images/phone.png';
import QRGetLinkApp from '@assets/icons/QRGetAppLink.svg';

export const QRCode = styled(QRGetLinkApp)`
    transform: scale(0.47);
    transform-origin: left top;

    @media ${({ theme }) => theme.breakpoints.xl} {
        transform: scale(0.375);
    }
`;

export const BannerWrapper = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.max}px;
    padding: 50px 0 58px 134px;
    ${({ theme }) => theme.mixins.flexStart};
    grid-column-gap: 52px;
    background: url(${PhoneImage}) no-repeat, ${({ theme }) => theme.colors.base()};
    background-size: 236px 352px;
    background-position: right 114px top 60px;
    @media ${({ theme }) => theme.breakpoints.xl} {
        background-position: right -68px top 40px;
        background-size: 208px auto;
        padding: 34px 161px 34px 40px;
        grid-column-gap: 23px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        background: ${({ theme }) => theme.colors.base()};
        padding: 34px 26px;
        display: grid;
        grid-template-column: 1fr;
    }
`;

export const QrWRapper = styled.div`
    margin-top: 8px;
    width: 154px;
    height: 154px;
    flex-shrink: 0;
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.xs}px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.white()};
    padding: 7px;

    @media ${({ theme }) => theme.breakpoints.xl} {
        width: 123px;
        height: 123px;
        padding: 6px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        margin: 0 auto 26px;
    }
`;

export const Content = styled.div`
    @media ${({ theme }) => theme.breakpoints.md} {
        display: contents;
    }
`;

export const BannerHead = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    grid-column-gap: 14px;
    margin-bottom: 7px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row-start: 1;
        grid-row-end: 2;
        margin: 0 auto 38px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        flex-direction: column;
    }
`;

export const BannerTitle = styled(Typography).attrs({ variant: 'bold24' })`
    color: ${({ theme }) => theme.colors.white()};
    @media ${({ theme }) => theme.breakpoints.sm} {
        margin: 0 auto;
    }
`;

export const LogoWrapper = styled.div`
    margin-top: 6px;
`;

export const BannerDescription = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.white()};
    margin-bottom: 22px;
    max-width: 425px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin: 0 auto 32px;
        text-align: center;
        max-width: 100%;
    }
`;

export const StoresApps = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 24px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-gap: 8px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-gap: 6px;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;

export const LinkToApp = styled.a`
    width: 102px;
    height: 30px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.xs}px;
    overflow: hidden;

    @media ${({ theme }) => theme.breakpoints.md} {
        max-width: 88px;
        max-height: 26px;
        width: 100%;
        svg {
            transform: scale(0.87);
            transform-origin: left top;
        }
    }
`;
