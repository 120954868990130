export enum enumExternalId {
    card = 'card',
    invoicing = 'invoicing',
    'internet-banking' = 'internet-banking',
}

export interface IPaymentMethod {
    id: number;
    title: string;
    description: string;
    externalId: enumExternalId;
    active?: boolean;
    shopId: number;
    paymentIntegrationId?: string;
}

interface IPaymentInfoFields {
    type: string;
    additionalProperties: boolean;
}

export interface IPaymentIntegration {
    id: string;
    title: string;
    paymentInfoFields?: IPaymentInfoFields;
}

export enum enumTypesPaymentAction {
    redirect = 'redirect',
    submit = 'submit',
    none = 'none',
    download = 'download',
}

export interface IPaymentAction {
    type: enumTypesPaymentAction;
    url: string;
    args: {
        [key: string]: string | number;
    };
}
