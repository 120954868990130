import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Main from '@pages/Main';
import { Routes } from '@pages/constants';
import Payment from '@pages/Payment';
import { fetchCart } from '@store/Cart';
import { useAppDispatch } from '@store';
import { fetchOrders } from '@store/Order';
import MainLayout from '../layouts/Main';

const AuthRouter = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchCart());
        dispatch(fetchOrders());
    }, [dispatch]);

    return (
        <BrowserRouter>
            <MainLayout>
                <Switch>
                    <Route path={Routes.main} component={Main} />
                    <Route path={Routes.order + Routes.paymentCard} component={Payment} />
                    <Route path={Routes.order + Routes.paymentAccount} component={Payment} />
                    <Route path={Routes.order + Routes.paymentInternetBank} component={Payment} />
                    <Route path={Routes.order + Routes.paymentAny} component={Payment} />
                    <Route path={`${Routes.order}/:orderId`} component={Payment} />
                    <Redirect to={Routes.main} />
                </Switch>
            </MainLayout>
        </BrowserRouter>
    );
};

export default AuthRouter;
