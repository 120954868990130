import styled from 'styled-components';
import DashedLineImage from '@assets/icons/dashedLine.svg';

export const PaymentProgressWrapper = styled.div`
    justify-content: flex-start;
    position: relative;
    display: inline-flex;
    grid-column-gap: 100px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-gap: 40px;
        justify-content: space-between;
        max-width: 380px;
        width: 100%;
        overflow: hidden;
    }
`;

interface IProgressCircle {
    isActive: boolean;
    isPassed: boolean;
}

export const ProgressCircle = styled.div<IProgressCircle>`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    ${({ theme }) => theme.mixins.getTypography('regular20')}
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid
        ${({ isActive, isPassed, theme }) => (isActive || isPassed ? theme.colors.green() : theme.colors.grey2())};
    background: ${({ isPassed, theme }) => (isPassed ? theme.colors.green() : theme.colors.white())};
`;

export const DashedLine = styled(DashedLineImage)`
    position: absolute;
    top: 50%;
    left: 60px;
    z-index: -1;
    transform: translateY(-50%);
`;
