import React, { FC, useCallback, useEffect, useState } from 'react';
import {
    BasketWrapper,
    ProductItemsWrapper,
    ProductsWrapper,
    ShopImage,
} from '@pages/Payment/components/ProductItems/styles';
import { enumSizeBasket } from '@pages/Payment/components/ButtonBasket/styles';
import { useAppDispatch } from '@store';
import { fetchGoods } from '@store/Good';
import ProductItem from '@pages/Payment/components/ProductItem';
import { useSelector } from 'react-redux';
import { getGoodsList } from '@store/Good/selectors';
import NotificationAddingItemsToBasket from '@pages/Payment/components/NotificationAddingItemsToBasket';
import ButtonBasket from '@pages/Payment/components/ButtonBasket';
import { IStep } from '@pages/Payment/types';
import SkeletonProducts from '@pages/Payment/components/SkeletonProducts';

const ProductItems: FC<IStep> = ({ goToNextStep }) => {
    const dispatch = useAppDispatch();

    const [isShowNotificationAdding, setIsShowNotificationAdding] = useState(false);
    const showNotificationAdding = useCallback(() => setIsShowNotificationAdding(true), []);
    const hideNotificationAdding = () => setIsShowNotificationAdding(false);

    const goodsList = useSelector(getGoodsList);

    useEffect(() => {
        dispatch(fetchGoods());
    }, [dispatch]);

    return (
        <ProductItemsWrapper>
            <ShopImage />
            <BasketWrapper>
                <ButtonBasket onClick={goToNextStep} size={enumSizeBasket.LARGE} />
            </BasketWrapper>
            <ProductsWrapper>
                {goodsList.length === 0 ? (
                    <SkeletonProducts />
                ) : (
                    goodsList.map((product) => (
                        <ProductItem
                            showNotificationAdding={showNotificationAdding}
                            key={product.id}
                            product={product}
                        />
                    ))
                )}
            </ProductsWrapper>
            {isShowNotificationAdding && (
                <NotificationAddingItemsToBasket
                    goToNextStep={goToNextStep}
                    hideNotificationAdding={hideNotificationAdding}
                />
            )}
        </ProductItemsWrapper>
    );
};

export default ProductItems;
