import React, { FC, useState } from 'react';
import {
    ArrowRight,
    ButtonTitle,
    Decor,
    DecorCircle,
    ImageWrapper,
    PaymentButtonWrapper,
} from '@pages/Main/components/PaymentButton/styles';
import { useHistory } from 'react-router-dom';
import { useLargeTablet } from '@hooks/useMedia';
import { transitionsDelayMS } from '@theme/decorations';
import ym from 'react-yandex-metrika';
import { enumGoalsYandexMetrika, enumMethodsYandexMetrika } from '@utils/constants';

interface IPaymentButton {
    title: string;
    Image: any;
    routePayment: string;
}

const PaymentButton: FC<IPaymentButton> = ({ routePayment, title, Image }) => {
    const history = useHistory();
    const isLargeTablet = useLargeTablet();

    const [isActiveDecorCircle, setIsActiveDecorCircle] = useState(false);

    const onClickPaymentButton = async () => {
        ym(enumMethodsYandexMetrika.reachGoal, enumGoalsYandexMetrika.CLICK_CASE);
        if (isLargeTablet) {
            setIsActiveDecorCircle(true);
            await setTimeout(() => {
                history.push(routePayment);
            }, transitionsDelayMS.secondary);
        } else {
            history.push(routePayment);
        }
    };

    return (
        <PaymentButtonWrapper isActiveDecorCircle={isActiveDecorCircle} onClick={onClickPaymentButton}>
            <ImageWrapper>
                <Image />
            </ImageWrapper>
            <ButtonTitle>{title}</ButtonTitle>
            <Decor>
                <DecorCircle />
                <ArrowRight />
            </Decor>
        </PaymentButtonWrapper>
    );
};

export default PaymentButton;
