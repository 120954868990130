import styled from 'styled-components';
import Typography from '@components/Typography';
import Modal from '@components/Modal';

export const ModalDelayWrapper = styled(Modal)``;

export const ModalTitle = styled(Typography).attrs({ variant: 'bold24' })`
    margin-bottom: 14px;
    white-space: nowrap;
    @media ${({ theme }) => theme.breakpoints.md} {
        white-space: break-spaces;
    }
`;

export const ParagraphsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 22px;
`;

export const ModalDescription = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey1()};
`;

export const OurPartnersTitle = styled(Typography).attrs({ variant: 'medium16' })`
    margin: 30px 0 16px 0;
`;

export const PartnersWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: repeat(3, 1fr);
    }
`;
