import { enumTypeEnvironment } from '@store/System/types';

export const PHONE_REGEX = /^(\+7|8)(?=(8|9)\d{3,9})/g;
export const ONLY_NUMBERS_AND_PLUS_REGEX = /[^+\d]/g;
export const IS_DEV = process.env.NODE_ENV === 'development';
export const appStoreAppLink =
    'https://apps.apple.com/ru/app/%D1%81%D1%87%D1%91%D1%82-%D0%B1%D1%83%D1%85%D0%B3%D0%B0%D0%BB%D1%82%D0%B5%D1%80%D1%83/id6450540522';
export const playMarketAppLink = 'https://play.google.com/store/apps/details?id=ru.invoicebox.invoiceForAccountant';
export const appGalleryAppLink = 'https://appgallery.huawei.com/app/C105350829';

export const invoiceBoxURLRU = 'https://www.invoicebox.ru/ru';
export const invoiceBoxURLEN = 'https://www.invoicebox.ru/en';

export const personalDataProcessingPoliceRU = 'https://www.invoicebox.ru/ru/privacypolicy/russia/main.html';
export const personalDataProcessingPoliceEN = 'https://www.invoicebox.ru/en/privacypolicy/russia/main.html';

// registrationLinks
export const registrationLinkByEnvironment = {
    [enumTypeEnvironment.develop]: 'https://app.dev.invbox.ru/?utm_source=demo&utm_medium=footer',
    [enumTypeEnvironment.staging]: 'https://app.stage.invbox.ru/?utm_source=demo&utm_medium=footer',
    [enumTypeEnvironment.production]: 'https://app.invoicebox.ru/?utm_source=demo&utm_medium=footer',
};

// demoStandLinks
export const linkDemoStandDev = 'https://demo.dev.invbox.ru';
export const linkDemoStandStage = 'https://demo.stage.invbox.ru';
export const linkDemoStandProd = 'https://demo.invoicebox.ru';

export const yandexMetrikaId = 88649021;

export enum enumMethodsYandexMetrika {
    reachGoal = 'reachGoal',
}

export enum enumGoalsYandexMetrika {
    CLICK_CASE = 'CLICK_CASE',
    BASKET_ADD = 'BASKET_ADD',
    CREATE_ORDER = 'CREATE_ORDER',
    CONTACT_FORM = 'CONTACT_FORM',
}

export const environmentLinks = {
    [enumTypeEnvironment.develop]: linkDemoStandDev,
    [enumTypeEnvironment.staging]: linkDemoStandStage,
    [enumTypeEnvironment.production]: linkDemoStandProd,
};

export const networkErrorResponse = 'Error: Network Error';
