import axios from 'axios';
import { IS_DEV } from './constants';

export const BASE_URL = IS_DEV ? 'https://api.expressclient.ru/api/shop/invoicebox/' : '/api/shop/invoicebox/';
export const STATIC_BASE_URL = 'https://api.expressclient.ru/static';

export type ICommonListRequest<D> = Partial<D> & {
    _page?: number;
    _pageSize?: number;
};

export interface ICommonResponse<D, E = any> {
    data: D;
    metaData: {
        totalCount: number;
        pageSize: number;
        page: number;
    };
    extendedData: Array<E>;
}

const http = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Content-Language': 'ru',
    },
});

export default http;
