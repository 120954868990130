import styled from 'styled-components';

export const LayoutWrapper = styled.div`
    min-height: 100vh;
    position: relative;
    overflow: hidden;
`;

export const PageContainer = styled.div`
    ${({ theme }) => theme.mixins.mainGrid};
    margin-top: 78px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 40px;
    }
`;
