import React, { FC, useCallback, useEffect, useState } from 'react';
import { ButtonClose, Title, ToastWrapper } from '@components/Toast/styles';
import IconClose from '@assets/icons/closeSquare.svg';

interface IToast {
    title: string | null;
    isShowToast: boolean;
    hideToast: () => void;
    afterCloseToast: () => void;
}

const Toast: FC<IToast> = ({ title, isShowToast, hideToast, afterCloseToast }) => {
    const delayClosedMs = 5000;
    const [timer, setTimer] = useState<any>();

    const onClose = useCallback(() => {
        afterCloseToast();
        hideToast();
    }, [hideToast, afterCloseToast]);

    useEffect(() => {
        if (isShowToast) {
            const newTimeout = setTimeout(() => onClose(), delayClosedMs);
            setTimer(newTimeout);
        }

        return clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowToast, onClose]);

    if (isShowToast) {
        return (
            <ToastWrapper>
                <Title>{title}</Title>
                <ButtonClose onClick={onClose}>
                    <IconClose />
                </ButtonClose>
            </ToastWrapper>
        );
    }
    return null;
};

export default Toast;
