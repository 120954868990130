import React from 'react';
// @ts-ignore
import MetaTags from 'react-meta-tags';
import ogImage from '@assets/images/ogImage.png';
import { useIntl } from 'react-intl';

const Meta = () => {
    const intl = useIntl();
    const { location } = document;

    return (
        <MetaTags>
            <title>
                {intl.formatMessage({ defaultMessage: 'Демо-стенд с продуктами и услугами системы Инвойсбокс' })}
            </title>
            <meta
                name="title"
                content={intl.formatMessage({
                    defaultMessage: 'Демо-стенд с продуктами и услугами системы Инвойсбокс',
                })}
            />
            <meta
                name="description"
                content={intl.formatMessage({
                    defaultMessage:
                        'Демонстрация сервисов по приёму платежей на сайте с использованием продуктов системы Инвойсбокс',
                })}
            />
            <meta
                name="keywords"
                content={intl.formatMessage({
                    defaultMessage:
                        'инвойсбокс, invoicebox, приём платежей, b2b, оплата по счёту, система оплаты, платёжная система, онлайн-касса, ФЗ-54, онлайн-чеки',
                })}
            />
            <meta name="author" content="Invoicebox" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="msapplication-TileColor" content="#FFD200" />
            <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
            <meta name="theme-color" content="#202932" />
            <meta name="apple-mobile-web-app-status-bar-style" content="#202932" />
            <meta name="apple-mobile-web-app-title" content="Invoicebox" />
            <meta name="application-name" content="Invoicebox" />
            <meta name="robots" content="index, follow" />
            <meta name="apple-mobile-web-app-title" content="Invoicebox" />
            <meta name="application-name" content="Invoicebox" />

            <meta property="og:type" content="website" />
            <meta
                property="og:title"
                content={intl.formatMessage({
                    defaultMessage: 'Демо-стенд с продуктами и услугами системы Инвойсбокс',
                })}
            />
            <meta
                property="og:description"
                content={intl.formatMessage({
                    defaultMessage:
                        'Демонстрация сервисов по приёму платежей на сайте с использованием продуктов системы Инвойсбокс',
                })}
            />
            <meta property="og:locale" content="ru_RU" />
            <meta property="og:image" content={`${location.origin}${ogImage}`} />
            <meta property="og:image:width" content="1000" />
            <meta property="og:image:height" content="1000" />
        </MetaTags>
    );
};

export default Meta;
