import styled, { css } from 'styled-components';

export const inputContainerStyles = css`
    position: absolute;
    right: 20px;
    bottom: calc(50% - 5px);
    transform: translateY(50%);
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;

export const IconContainer = styled.span`
    ${inputContainerStyles};
    pointer-events: none;
`;

export const IconClickableContainer = styled.button`
    ${inputContainerStyles};
    cursor: pointer;

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
`;
