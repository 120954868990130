import styled from 'styled-components';
import Typography from '@components/Typography';
import Button from '@components/Button';

export const InvoiceServicesWrapper = styled.div`
    padding: 64px 0 88px 0;
    background-color: ${({ theme }) => theme.colors.grey5()};
    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 55px 68px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 20px 10px 35px 10px;
    }
`;

export const InvoiceTitle = styled(Typography).attrs({ variant: 'bold34' })`
    margin-bottom: 54px;
    text-align: center;
    @media ${({ theme }) => theme.breakpoints.ld} {
        text-align: left;
        margin-bottom: 38px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('bold20')}
        margin-bottom: 20px;
    }
`;

export const ServicesWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    justify-content: flex-start;
    margin: 0 auto;
    width: 950px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        flex-direction: column;
        width: 100%;
    }
`;

export const Service = styled.div`
    width: 480px;
    padding: 4px 0 18px 0;
    &:nth-child(2n-1) {
        padding-right: 90px;
        padding-left: 20px;
        border-right: 1px solid ${({ theme }) => theme.colors.grey3()};
    }
    &:nth-child(2n) {
        padding-left: 90px;
        padding-right: 20px;
    }
    @media ${({ theme }) => theme.breakpoints.ld} {
        height: auto;
        width: 100%;
        &:nth-child(2n-1) {
            border: none;
            padding: 0;
            padding-bottom: 40px;
            border-bottom: 1px solid ${({ theme }) => theme.colors.grey3()};
        }
        &:nth-child(2n) {
            padding: 0;
            padding-top: 40px;
        }
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        &:nth-child(2n-1) {
            padding-bottom: 30px;
        }
        &:nth-child(2n) {
            padding-top: 30px;
        }
    }
`;

export const ServiceTitle = styled(Typography).attrs({ variant: 'regular20' })`
    margin-bottom: 20px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 8px;
        ${({ theme }) => theme.mixins.getTypography('regular16')}
    }
`;

export const ServiceDescription = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey1()};
    margin-bottom: 36px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        margin-bottom: 33px;
    }
`;

export const ButtonWrapper = styled.div`
    position: relative;
    display: inline-block;
    @media ${({ theme }) => theme.breakpoints.sm} {
        display: block;
    }
`;

export const ButtonService = styled(Button)`
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 100%;
    }
`;

export const PromptButton = styled.button`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.yellow()};
    position: absolute;
    z-index: 1;
    top: -16px;
    right: -16px;
    @media ${({ theme }) => theme.breakpoints.md} {
        left: 50%;
        transform: translateX(-50%);
    }
`;
