export type TTypographyItem = {
    fontSize: number;
    lineHeight: number;
    fontWeight?: number;
    textTransform?: string;
};

export type TTypography = {
    regular10: TTypographyItem;
    regular12: TTypographyItem;
    medium12: TTypographyItem;
    regular14: TTypographyItem;
    bold14: TTypographyItem;
    regular16: TTypographyItem;
    medium16: TTypographyItem;
    regular20: TTypographyItem;
    bold20: TTypographyItem;
    regular24: TTypographyItem;
    bold24: TTypographyItem;
    bold34: TTypographyItem;
    regular48: TTypographyItem;
    bold48: TTypographyItem;
};

const typography: TTypography = {
    regular10: {
        fontSize: 10,
        lineHeight: 16,
    },
    regular12: {
        fontSize: 12,
        lineHeight: 16,
    },
    medium12: {
        fontSize: 12,
        lineHeight: 16,
        fontWeight: 500,
    },
    regular14: {
        fontSize: 14,
        lineHeight: 22,
        fontWeight: 400,
    },
    bold14: {
        fontSize: 14,
        lineHeight: 22,
        fontWeight: 700,
    },
    regular16: {
        fontSize: 16,
        lineHeight: 22,
    },
    medium16: {
        fontSize: 16,
        lineHeight: 22,
        fontWeight: 500,
    },
    regular20: {
        fontSize: 20,
        lineHeight: 28,
    },
    bold20: {
        fontSize: 20,
        lineHeight: 28,
        fontWeight: 700,
    },
    regular24: {
        fontSize: 24,
        lineHeight: 32,
    },
    bold24: {
        fontSize: 24,
        lineHeight: 32,
        fontWeight: 700,
    },
    bold34: {
        fontSize: 34,
        lineHeight: 42,
        fontWeight: 700,
    },
    regular48: {
        fontSize: 48,
        lineHeight: 56,
    },
    bold48: {
        fontSize: 48,
        lineHeight: 56,
        fontWeight: 700,
    },
};

export default typography;
