import React from 'react';
import {
    BaseCircle,
    ButtonConsultation,
    MainHead,
    MainWrapper,
    Subtitle,
    Title,
    TitleName,
    TitleWrapper,
    YellowCircle,
} from '@pages/Main/styles';
import PaymentMethod from '@pages/Main/components/PaymentMethod';
import AppBanner from '@pages/Main/components/AppBanner';
import useModal from '@hooks/useModal';
import ModalConsultation from '@pages/Main/components/ModalConsultation';
import { enumGoalsYandexMetrika, enumMethodsYandexMetrika } from '@utils/constants';
import ym from 'react-yandex-metrika';
import { useIntl } from 'react-intl';

const Main = () => {
    const intl = useIntl();
    const { isOpen: isOpenModal, open: openModal, close: onCloseModal } = useModal(false);

    const onClickButtonConsultation = () => {
        ym(enumMethodsYandexMetrika.reachGoal, enumGoalsYandexMetrika.CONTACT_FORM);
        openModal();
    };

    return (
        <MainWrapper>
            <MainHead>
                <TitleWrapper>
                    <Title>
                        {intl.formatMessage({
                            defaultMessage: 'Демо-стенд системы',
                            description: 'Демо-стенд системы Инвойсбокс',
                        })}{' '}
                        <TitleName>
                            {intl.formatMessage({
                                defaultMessage: 'Инвойсбокс',
                                description: 'Демо-стенд системы Инвойсбокс',
                            })}
                        </TitleName>
                    </Title>
                    <ButtonConsultation onClick={onClickButtonConsultation}>
                        {intl.formatMessage({ defaultMessage: 'Заявка на консультацию' })}
                    </ButtonConsultation>
                </TitleWrapper>
                <Subtitle>
                    {intl.formatMessage({
                        defaultMessage: 'Основные способы онлайн-оплаты, которые предоставляет система Инвойсбокс',
                    })}
                </Subtitle>
            </MainHead>
            <PaymentMethod />
            <AppBanner />
            <YellowCircle />
            <BaseCircle />
            <ModalConsultation isOpen={isOpenModal} closeModal={onCloseModal} />
        </MainWrapper>
    );
};

export default Main;
