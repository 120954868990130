import React, { FC } from 'react';
import {
    AddressField,
    CheckboxConsent,
    InputsWrapper,
    LinkToPrivacy,
} from '@pages/Payment/components/ContactInfo/styles';
import FormField, { FormFieldTypeEnum } from '@components/FormField';
import { ORDER_FIELDS } from '@store/Order/types';
import { InputTypeEnum } from '@components/Input';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getLanguage } from '@store/System/selectors';
import { personalDataProcessingPolice } from '@pages/Payment/types';
import { phoneFieldFormatters } from '@utils/formatters';

interface IContactInfo {
    isLegal: boolean;
}

const ContactInfo: FC<IContactInfo> = ({ isLegal }) => {
    const intl = useIntl();

    const language = useSelector(getLanguage);

    return (
        <InputsWrapper>
            <FormField
                isRounded={false}
                label={intl.formatMessage({ defaultMessage: 'Телефон' })}
                name={ORDER_FIELDS.contactPhone}
                mask="+7 999 999 99 99"
                placeholder="+7 XXX XXX XX XX"
                {...phoneFieldFormatters}
            />
            <FormField
                isRounded={false}
                label="Email"
                name={ORDER_FIELDS.contactEmail}
                placeholder={intl.formatMessage({ defaultMessage: 'Укажите электронную почту' })}
            />
            <FormField
                isRounded={false}
                label={
                    isLegal
                        ? intl.formatMessage({ defaultMessage: 'Правовая форма и наименование организации' })
                        : intl.formatMessage({ defaultMessage: 'ФИО' })
                }
                name={isLegal ? `${ORDER_FIELDS.paymentInfo}.${ORDER_FIELDS.name}` : ORDER_FIELDS.contactFio}
                placeholder={
                    isLegal
                        ? intl.formatMessage({ defaultMessage: 'Правовая форма и наименование организации или ИП' })
                        : intl.formatMessage({ defaultMessage: 'Заполните поле' })
                }
            />
            {isLegal && (
                <>
                    <FormField
                        isRounded={false}
                        label={intl.formatMessage({ defaultMessage: 'ИНН' })}
                        name={`${ORDER_FIELDS.paymentInfo}.${ORDER_FIELDS.vatNumber}`}
                        placeholder={intl.formatMessage({ defaultMessage: 'Укажите ИНН организации или ИП' })}
                        typeInput={InputTypeEnum.numeric}
                        maxLength={12}
                    />
                    <AddressField
                        isRounded={false}
                        label={intl.formatMessage({ defaultMessage: 'Юридический адрес' })}
                        name={`${ORDER_FIELDS.paymentInfo}.${ORDER_FIELDS.registrationAddress}`}
                        placeholder={intl.formatMessage({ defaultMessage: 'Индекс, город, улица, офис...' })}
                    />
                </>
            )}
            <CheckboxConsent
                type="checkbox"
                fieldType={FormFieldTypeEnum.checkbox}
                isLegal={isLegal}
                label={
                    <>
                        {intl.formatMessage({
                            defaultMessage: 'Подтверждаю, что все данные введены корректно, и даю',
                            description:
                                'Подтверждаю, что все данные введены корректно, и даю согласие на обработку моих персональных данных',
                        })}
                        <LinkToPrivacy href={personalDataProcessingPolice[language]} target="_blank">
                            {' '}
                            {intl.formatMessage({
                                defaultMessage: 'согласие на обработку моих персональных данных',
                                description:
                                    'Подтверждаю, что все данные введены корректно, и даю согласие на обработку моих персональных данных',
                            })}
                        </LinkToPrivacy>
                    </>
                }
                name="consentProcessingPersonalData"
            />
        </InputsWrapper>
    );
};

export default ContactInfo;
