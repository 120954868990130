import React, { FC } from 'react';
import { enumTypeEnvironment } from '@store/System/types';
import { environmentLinks } from '@utils/constants';
import { EnvironmentItemButton, IconWrapper } from './styles';
import { environmentIcon } from '../Enviroment';

interface IEnvironmentItem {
    environment: string;
    isActive: boolean;
    environmentLabel: {
        [enumTypeEnvironment.develop]: string;
        [enumTypeEnvironment.staging]: string;
        [enumTypeEnvironment.production]: string;
    };
}

const EnvironmentItem: FC<IEnvironmentItem> = ({ environment, isActive, environmentLabel }) => {
    const envToEnum: enumTypeEnvironment = environment as any;

    return (
        <EnvironmentItemButton href={environmentLinks[envToEnum]}>
            <IconWrapper isActive={isActive}>{environmentIcon[envToEnum]}</IconWrapper>
            {environmentLabel[envToEnum]}
        </EnvironmentItemButton>
    );
};

export default EnvironmentItem;
