import styled from 'styled-components';
import Typography from '@components/Typography';
import Button from '@components/Button';

export const MainWrapper = styled.div`
    padding-bottom: 124px;
    @media ${({ theme }) => theme.breakpoints.xl} {
        padding: 0 36px 140px 36px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 0 16px 100px 16px;
    }
`;

export const TitleWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    @media ${({ theme }) => theme.breakpoints.ld} {
        display: contents;
    }
`;

export const Title = styled(Typography).attrs({ variant: 'regular48' })`
    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.getTypography('regular24')}
    }
`;

export const TitleName = styled.span`
    ${({ theme }) => theme.mixins.getTypography('bold48')}
    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.getTypography('bold24')}
    }
`;

export const Subtitle = styled(Typography).attrs({ variant: 'regular20' })`
    color: ${({ theme }) => theme.colors.grey1()};
    margin-top: 24px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-top: 12px;
        ${({ theme }) => theme.mixins.getTypography('regular16')}
    }
`;

export const YellowCircle = styled.div`
    width: 375px;
    height: 375px;
    border: 80px solid ${({ theme }) => theme.colors.yellow()};
    border-radius: 50%;
    position: absolute;
    right: -200px;
    bottom: 60px;
    z-index: -1;
    @media ${({ theme }) => theme.breakpoints.ld} {
        right: -50px;
        bottom: 30px;
        border: 40px solid ${({ theme }) => theme.colors.yellow()};
        width: 170px;
        height: 170px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        right: -60px;
        bottom: 160px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 130px;
        height: 130px;
        right: -50px;
        bottom: 180px;
        border: 30px solid ${({ theme }) => theme.colors.yellow()};
    }
`;

export const BaseCircle = styled.div`
    width: 274px;
    height: 274px;
    border: 70px solid ${({ theme }) => theme.colors.base()};
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: -40px;
    z-index: -2;
    @media ${({ theme }) => theme.breakpoints.ld} {
        width: 130px;
        height: 130px;
        bottom: 18px;
        right: 50px;
        border: 30px solid ${({ theme }) => theme.colors.base()};
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        bottom: 160px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 100px;
        height: 100px;
        bottom: 180px;
        right: 35px;
        border: 25px solid ${({ theme }) => theme.colors.base()};
    }
`;

export const ButtonConsultation = styled(Button)`
    flex-shrink: 0;
    max-width: 236px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-row-start: 3;
        grid-row-end: 4;
        margin-top: 40px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-top: 30px;
    }
`;

export const MainHead = styled.div`
    display: contents;
    @media ${({ theme }) => theme.breakpoints.ld} {
        display: grid;
        grid-template-columns: 1fr;
    }
`;
