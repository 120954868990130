import React, { FC, useEffect } from 'react';
import { Field, useField } from 'react-final-form';
import { useSelector } from 'react-redux';
import { getPaymentMethodById } from '@store/PaymentMethods/selectors';
import {
    PayIconsWrapper,
    PaymentHeader,
    PaymentMethodDescription,
    PaymentMethodName,
    PaymentMethodRadio,
    PaymentMethodsWrapper,
    PaymentMethodWrapper,
} from '@pages/Payment/components/PaymentMethods/styles';
import Radio from '@components/Radio';
import { ORDER_FIELDS } from '@store/Order/types';
import { enumExternalId, IPaymentMethod } from '@store/PaymentMethods/types';
import Mir from '@assets/icons/paymentMethods/mir.svg';
import Visa from '@assets/icons/paymentMethods/visa.svg';
import MasterCard from '@assets/icons/paymentMethods/masterCard.svg';
import Sbp from '@assets/icons/paymentMethods/sbp.svg';

interface IPaymentMethods {
    paymentsMethodsToCurrentTypePayment: IPaymentMethod[];
    setIsLegal: (arg: boolean) => void;
}

const PaymentMethods: FC<IPaymentMethods> = ({ paymentsMethodsToCurrentTypePayment, setIsLegal }) => {
    const {
        input: { value: shopPaymentId },
    } = useField(ORDER_FIELDS.shopPaymentId);

    const selectedPaymentMethod = useSelector(getPaymentMethodById(shopPaymentId));

    useEffect(() => {
        if (selectedPaymentMethod?.externalId === enumExternalId.invoicing) {
            setIsLegal(true);
        } else {
            setIsLegal(false);
        }
    }, [selectedPaymentMethod?.externalId, setIsLegal]);

    return (
        <PaymentMethodsWrapper>
            {paymentsMethodsToCurrentTypePayment.map((paymentMethod) => (
                <Field
                    name={ORDER_FIELDS.shopPaymentId}
                    type="radio"
                    value={paymentMethod.id.toString()}
                    key={paymentMethod.id}
                >
                    {({ input }) => (
                        <PaymentMethodWrapper>
                            <Radio checked={!!input.checked} {...input}>
                                <PaymentMethodRadio>
                                    <PaymentHeader>
                                        <PaymentMethodName>{paymentMethod.title}</PaymentMethodName>
                                        {paymentMethod.externalId === enumExternalId.card && (
                                            <PayIconsWrapper>
                                                <Mir />
                                                <Visa />
                                                <MasterCard />
                                                <Sbp />
                                            </PayIconsWrapper>
                                        )}
                                    </PaymentHeader>
                                    {paymentMethod.description && (
                                        <PaymentMethodDescription>{paymentMethod.description}</PaymentMethodDescription>
                                    )}
                                </PaymentMethodRadio>
                            </Radio>
                        </PaymentMethodWrapper>
                    )}
                </Field>
            ))}
        </PaymentMethodsWrapper>
    );
};

export default PaymentMethods;
