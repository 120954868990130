import styled from 'styled-components';
import shopLogoImage from '@assets/icons/shopLogo.svg';

export const ProductItemsWrapper = styled.div`
    position: relative;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow?.base};
    padding: 44px 36px 72px 36px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 44px 24px 72px 24px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 30px 10px 45px 10px;
    }
`;

export const ShopImage = styled(shopLogoImage)`
    display: block;
    margin: 0 auto 45px;
`;

export const BasketWrapper = styled.div`
    position: absolute;
    top: 49px;
    right: 55px;
`;

export const ProductsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 42px;
    grid-row-gap: 52px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 12px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row-gap: 20px;
    }
`;
