import Button from '@components/Button';
import styled from 'styled-components';

export const FooterWrapper = styled.div`
    background: ${({ theme }) => theme.colors.grey4()};
    @media ${({ theme }) => theme.breakpoints.md} {
        height: auto;
    }
`;

export const FooterContent = styled.div`
    ${({ theme }) => theme.mixins.mainGrid};
    height: 100%;
    ${({ theme }) => theme.mixins.flexStart};
    justify-content: space-between;
    padding: 38px 0;

    @media ${({ theme }) => theme.breakpoints.xl} {
        padding: 38px 36px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: column;
        align-items: center;
        padding: 40px 36px;
    }
`;

export const ButtonPrivacy = styled(Button)`
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-row: 2;
        grid-column: 1/3;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column: auto;
        grid-row: 3;
    }
`;

export const ButtonsWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 34px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-row-gap: 34px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 26px;
    }
`;
