import { AsyncThunkOptions, AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '@store';
import commonThunkHandler from './commonThunkHandler';

interface IThunkConfig {
    state: RootState;
    dispatch: AppDispatch;
}

function createAsyncThunkWithErrorHandler<A, B = void>(
    name: string,
    callback: AsyncThunkPayloadCreator<A, B, IThunkConfig>,
    options?: AsyncThunkOptions<B, IThunkConfig>,
) {
    return createAsyncThunk<A, B, IThunkConfig>(name, commonThunkHandler(callback), options);
}

export default createAsyncThunkWithErrorHandler;
