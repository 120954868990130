import React, { useEffect, useState } from 'react';
import {
    ButtonBackToMain,
    HeaderButtons,
    PaymentHead,
    PaymentInfo,
    PaymentTitle,
    PaymentWrapper,
} from '@pages/Payment/styles';
import HomeIcon from '@assets/icons/home.svg';
import { Routes } from '@pages/constants';
import PaymentProgress from '@pages/Payment/components/PaymentProgress';
import HelperAddingItems from '@pages/Payment/components/HelperAddingItems';
import ProductItems from '@pages/Payment/components/ProductItems';
import { useLocation, useParams } from 'react-router-dom';
import { useMobile } from '@hooks/useMedia';
import Basket from '@pages/Payment/components/Basket';
import HelperPayingCard from '@pages/Payment/components/HelperPayingCard';
import DetailPayment from '@pages/Payment/components/DetailPayment';
import { enumExternalId } from '@store/PaymentMethods/types';
import HelperDetailPayment from '@pages/Payment/components/HelperDetailPayment';
import { useSWR } from '@hooks/useSWR';
import { IOrderResponse } from '@store/Order/types';
import { useSelector } from 'react-redux';
import { getPaymentMethodById } from '@store/PaymentMethods/selectors';
import { enumTypePayment, stepsPayment } from '@pages/Payment/types';
import { useIntl } from 'react-intl';

export const stepKeep = {
    [stepsPayment.addingItems]: {
        mainComponent: ProductItems,
        helperComponent: HelperAddingItems,
    },
    [stepsPayment.payment]: {
        mainComponent: Basket,
        helperComponent: HelperPayingCard,
    },
    [stepsPayment.successPayment]: {
        mainComponent: DetailPayment,
        helperComponent: HelperDetailPayment,
    },
};

const Payment = () => {
    const intl = useIntl();

    const typePaymentKeep = {
        [enumTypePayment.paymentCard]: {
            title: intl.formatMessage({ defaultMessage: 'Оплата банковской картой' }),
            external: [enumExternalId.card],
        },
        [enumTypePayment.paymentAccount]: {
            title: intl.formatMessage({ defaultMessage: 'Оплата по счёту для организаций и ИП' }),
            external: [enumExternalId.invoicing],
        },
        [enumTypePayment.paymentInternetBank]: {
            title: intl.formatMessage({ defaultMessage: 'Оплата через интернет-банк' }),
            external: [enumExternalId['internet-banking']],
        },
        [enumTypePayment.paymentAny]: {
            title: intl.formatMessage({ defaultMessage: 'Оплата с выбором способа оплаты' }),
            external: [enumExternalId.card, enumExternalId.invoicing, enumExternalId['internet-banking']],
        },
    };

    const isMobile = useMobile();
    const location = useLocation();
    const { orderId } = useParams<{ orderId: string }>();

    const [step, setStep] = useState(stepsPayment.addingItems);
    const goToNextStep = () => setStep(step + 1);
    const goToPrevStep = () => setStep(step - 1);

    // fetch for last step
    const { data } = useSWR<IOrderResponse>(
        `order/${orderId}`,
        {
            _extend: ['payment-action', 'cart'],
        },
        Boolean(orderId),
    );
    const order = data?.data;
    const cart = data?.extendedData && data?.extendedData.find((item) => item.entity === 'cart')?.data[0];
    const paymentAction =
        data?.extendedData && data?.extendedData.find((item) => item.entity === 'payment-action')?.data[0];

    const paymentMethod = useSelector(getPaymentMethodById(order?.shopPaymentId || -1));
    const isInvoicePaymentType = paymentMethod?.externalId === enumExternalId.invoicing;
    const isInternetBankPaymentType = paymentMethod?.externalId === enumExternalId['internet-banking'];

    const getTypePayment = (): enumTypePayment => {
        if (order) {
            if (isInvoicePaymentType) {
                return enumTypePayment.paymentAccount;
            }
            if (isInternetBankPaymentType) {
                return enumTypePayment.paymentInternetBank;
            }
            return enumTypePayment.paymentCard;
        }
        // @ts-ignore
        return (
            Object.entries(enumTypePayment).find((typePayment) =>
                location?.pathname?.includes(typePayment?.[0]),
            )?.[1] || enumTypePayment.paymentCard
        );
    };

    const currentTypePayment: enumTypePayment = getTypePayment();

    const { title } = typePaymentKeep[currentTypePayment];
    const isPaymentCard = order ? !isInvoicePaymentType : currentTypePayment === enumTypePayment.paymentCard;

    const CurrentStepComponent = stepKeep[step].mainComponent;
    const CurrentStepHelper = stepKeep[step].helperComponent;

    useEffect(() => {
        if (orderId) {
            setStep(2);
        }
    }, [orderId]);

    return (
        <PaymentWrapper>
            <PaymentHead>
                <PaymentTitle>{title}</PaymentTitle>
                <HeaderButtons>
                    {/* todo пока нет возможности повторять */}
                    {/* {isPaid && ( */}
                    {/*    <ButtonRepeatOrder onClick={() => {}} icon={<Repeat />} type="link"> */}
                    {/*        Повторить кейс */}
                    {/*    </ButtonRepeatOrder> */}
                    {/* )} */}
                    <ButtonBackToMain to={Routes.main} icon={<HomeIcon />} type="link">
                        {isMobile
                            ? intl.formatMessage({ defaultMessage: 'На главную' })
                            : intl.formatMessage({ defaultMessage: 'Вернуться на главную' })}
                    </ButtonBackToMain>
                </HeaderButtons>
            </PaymentHead>
            <PaymentInfo>
                <PaymentProgress currentPaymentStepIndex={step} />
                <CurrentStepHelper isPaymentCard={isPaymentCard} goToNextStep={goToNextStep} />
            </PaymentInfo>
            <CurrentStepComponent
                goToNextStep={goToNextStep}
                goToPrevStep={goToPrevStep}
                currentTypePayment={currentTypePayment}
                order={order}
                cart={cart}
                paymentAction={paymentAction}
                typePaymentKeep={typePaymentKeep}
            />
        </PaymentWrapper>
    );
};

export default Payment;
