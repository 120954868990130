import styled from 'styled-components';
import Skeleton from '@components/Skeleton';
import {
    buttonAddToBasketStyles,
    imageStyles,
    productWrapperStyles,
    titleStyle,
} from '@pages/Payment/components/ProductItem/styles';

export const SkeletonWrapper = styled.div`
    ${productWrapperStyles}
`;

export const SkeletonImage = styled(Skeleton)`
    ${imageStyles}
`;

export const SkeletonPrice = styled(Skeleton)`
    margin-top: 1px;
    height: 22px;
    width: 130px;
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 60px;
    }
`;

export const SkeletonButton = styled(Skeleton)`
    ${buttonAddToBasketStyles}
`;

export const SkeletonTitle = styled(Skeleton)`
    height: 28px;
    width: 150px;
    ${titleStyle}
`;
