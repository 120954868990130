import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { addGoodToCartRequest, getCartRequest } from '@store/Cart/http';
import { Nullable } from '@utils/utilityTypes';
import { TCart, TCartItems } from '@store/Cart/types';
import createAsyncThunkWithErrorHandler from '@utils/helpers/createAsyncThunkWithErrorHandler';
import { setShopId } from '@store/System';

export const fetchCart = createAsyncThunkWithErrorHandler('cart/getAll', async (arg, thunkAPI) => {
    const {
        data: { data: dataCart },
    } = await getCartRequest();
    if (dataCart?.shopId) {
        thunkAPI.dispatch(setShopId(dataCart?.shopId));
    }
    return dataCart;
});

export const addGoodToCart = createAsyncThunkWithErrorHandler('cart/addGood', async (cardItems: TCartItems) => {
    const {
        data: { data: dataCart },
    } = await addGoodToCartRequest(cardItems);
    return dataCart;
});

export const cartAdapter = createEntityAdapter();

interface ICartState {
    isLoading: boolean;
    data: Nullable<TCart>;
}

const initialState = cartAdapter.getInitialState({
    isLoading: true,
    data: null,
} as ICartState);

const cart = createSlice({
    name: 'cart',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCart.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCart.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.data = payload;
        });
        builder.addCase(fetchCart.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(addGoodToCart.fulfilled, (state, { payload }) => {
            state.data = payload;
        });
    },
});

export default cart.reducer;
