import styled, { DefaultTheme } from 'styled-components';

export const HiddenInput = styled.input`
    display: none;
`;

export const RadioIcon = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white()};
    transition: ${({ theme }) => theme.decorations.transition?.base};
`;

interface IStyledRadioProps {
    checked: boolean;
    disabled?: boolean;
}

const getColorBgToStyledRadio = (theme: DefaultTheme, checked: boolean, disabled: boolean | undefined) => {
    if (disabled) {
        return theme.colors.grey4();
    }
    if (checked) {
        return theme.colors.base();
    }
    return theme.colors.grey2();
};

export const StyledRadio = styled.div<IStyledRadioProps>`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    width: 20px;
    height: 20px;
    background-color: ${({ checked, theme, disabled }) => getColorBgToStyledRadio(theme, checked, disabled)};
    border-radius: 100%;
    transition: ${({ theme }) => theme.decorations.transition?.base};
    cursor: pointer;

    ${RadioIcon} {
        opacity: ${(props) => (props.checked ? 1 : 0)};
    }
`;

export const RadioContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

export const RadioLabel = styled.label`
    ${({ theme }) => theme.mixins.flexStart};
    grid-column-gap: 12px;

    &:hover {
        ${StyledRadio} {
            background-color: ${({ theme }) => theme.colors.base()};
        }
    }
`;
