import React, { FC } from 'react';
import {
    ModalDelayWrapper,
    ModalDescription,
    ModalTitle,
    OurPartnersTitle,
    ParagraphsWrapper,
    PartnersWrapper,
} from '@components/ModalDelayPay/styles';
import Aeroflot from '@assets/icons/patners/aeroflot.svg';
import AirFrance from '@assets/icons/patners/airFrance.svg';
import Russia from '@assets/icons/patners/russia.svg';
import S7 from '@assets/icons/patners/s7.svg';
import MosGorTrance from '@assets/icons/patners/mosGorTrance.svg';
import MosTransport from '@assets/icons/patners/mosTransport.svg';
import Vprok from '@assets/icons/patners/vprok.svg';
import AeroExpress from '@assets/icons/patners/aeroExpress.svg';
import RedWings from '@assets/icons/patners/redwings.svg';
import Tokio from '@assets/icons/patners/tokio.svg';
import Smartavia from '@assets/icons/patners/smartavia.svg';
import Iraero from '@assets/icons/patners/iraero.svg';
import Azimut from '@assets/icons/patners/azimut.svg';
import Sendit from '@assets/icons/patners/sendit.svg';
import Aurora from '@assets/icons/patners/aurora.svg';
import Belavia from '@assets/icons/patners/belavia.svg';
import Moby from '@assets/icons/patners/moby.svg';
import Utair from '@assets/icons/patners/utair.svg';
import Ufs from '@assets/icons/patners/ufs.svg';
import Setup from '@assets/icons/patners/setup.svg';
import X5 from '@assets/icons/patners/x5.svg';
import Klm from '@assets/icons/patners/klm.svg';
import { useIntl } from 'react-intl';

export enum enumTypeDelay {
    fourDays = 'fourDays',
    thirtyDays = 'thirtyDays',
}

interface IModalDelayPay {
    typeDelay: enumTypeDelay;
    isOpen: boolean;
    closeModal: () => void;
}

const arrPartners = [
    Aeroflot,
    AirFrance,
    Russia,
    S7,
    MosGorTrance,
    MosTransport,
    Vprok,
    AeroExpress,
    RedWings,
    Tokio,
    Smartavia,
    Iraero,
    Azimut,
    Sendit,
    Aurora,
    Belavia,
    Moby,
    Utair,
    Ufs,
    Setup,
    X5,
    Klm,
];

const ModalDelayPay: FC<IModalDelayPay> = ({ isOpen, closeModal, typeDelay = enumTypeDelay.fourDays }) => {
    const intl = useIntl();

    const delays = {
        [enumTypeDelay.fourDays]: {
            title: intl.formatMessage({ defaultMessage: 'Отсрочка платежа до 4 дней' }),
            paragraphs: [
                intl.formatMessage({
                    defaultMessage:
                        'Если ваша организация не подключена к системе “Инвойсбокс.Бизнес”, для моментального подтверждения оплаты счёта вы можете воспользоваться временной гарантийной блокировкой средств банковской карте.',
                }),
                intl.formatMessage({
                    defaultMessage: 'На карте будет заблокирована полная сумма заказа и сумма сбора за неоплату счёта.',
                }),
                intl.formatMessage({
                    defaultMessage:
                        'Сразу после гарантийной блокировки средств на карте магазин получит подтверждение оплаты счёта и сможет оказать вам услугу или отгрузить товар.',
                }),
                intl.formatMessage({
                    defaultMessage: 'Оплатить счёт при гарантийной блокировке можно в срок до 4 дней.',
                }),
                intl.formatMessage({
                    defaultMessage: 'После оплаты по счёту блокировка средств будет снята в полном объёме.',
                }),
                intl.formatMessage({
                    defaultMessage:
                        'Если оплата счёта организации не будет проведена в указанный срок, с карты спишется вся заблокированная сумма и будет оформлен и отправлен фискальный чек.',
                }),
            ],
            otherContent: <></>,
        },
        [enumTypeDelay.thirtyDays]: {
            title: intl.formatMessage({ defaultMessage: 'Отсрочка платежа до 30 дней' }),
            paragraphs: [
                intl.formatMessage({
                    defaultMessage:
                        'После регистрации в “Инвойсбокс.Бизнес” организация может перечислить средства для формирования гарантийного фонда, в пределах которого можно подтверждать оплату заказа до оплаты счёта, а счёт оплачивать в течение 30 дней.',
                }),
                intl.formatMessage({
                    defaultMessage:
                        'После оплаты по счёту размер гарантийного фонда восстанавливается до исходной суммы и используется для дальнейших покупок.',
                }),
                intl.formatMessage({
                    defaultMessage:
                        'Личный кабинет Инвойсбокс позволяет установить уровни доступа и лимиты на расходование средств гарантийного фонда для сотрудников компании.',
                }),
            ],
            otherContent: (
                <>
                    <OurPartnersTitle>{intl.formatMessage({ defaultMessage: 'Наши партнеры' })}</OurPartnersTitle>
                    <PartnersWrapper>
                        {arrPartners.map((Partner) => (
                            <Partner key={Partner} />
                        ))}
                    </PartnersWrapper>
                </>
            ),
        },
    };

    const selectedDelay = delays[typeDelay];

    return (
        <ModalDelayWrapper closeModal={closeModal} isOpen={isOpen}>
            <ModalTitle>{selectedDelay.title}</ModalTitle>
            <ParagraphsWrapper>
                {selectedDelay.paragraphs.map((paragraph) => (
                    <ModalDescription key={paragraph}>{paragraph}</ModalDescription>
                ))}
            </ParagraphsWrapper>
            {selectedDelay.otherContent}
        </ModalDelayWrapper>
    );
};

export default ModalDelayPay;
