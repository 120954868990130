import styled from 'styled-components';
import CheckIcon from '@components/Checkbox/assets/check.svg';
import colors from '@theme/colors';
import Typography from '@components/Typography';

export const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

export const HiddenCheckbox = styled.input`
    display: none;
`;

export const CheckboxIcon = styled(CheckIcon)`
    transition: ${({ theme }) => theme.decorations.transition?.base};
`;

export const StyledCheckbox = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    width: 18px;
    height: 18px;
    background: ${({ theme }) => theme.colors.grey2()};
    transition: ${({ theme }) => theme.decorations.transition?.base};
`;

const styleBackground = 'background-color: ';

const getStylesCheckbox = (disabled: boolean, checked: boolean) => {
    if (disabled) {
        return 'opacity: 0.5;';
    }
    if (checked) {
        return `${styleBackground}${colors.base()}`;
    }
};

const getHoverStylesCheckbox = (disabled: boolean) => {
    if (disabled) {
        return 'none';
    }
    return `${styleBackground}${colors.base()}`;
};

interface IStyledCheckboxProps {
    checked: boolean;
    disabled: boolean;
}

export const CheckboxLabel = styled.label<IStyledCheckboxProps>`
    ${({ theme }) => theme.mixins.flexStart};
    grid-column-gap: 10px;
    user-select: none;
    width: fit-content;
    cursor: pointer;

    ${StyledCheckbox} {
        ${({ checked, disabled }) => getStylesCheckbox(disabled, checked)};

        ${CheckboxIcon} {
            opacity: ${(props) => (props.checked ? 1 : 0)};
        }
    }

    &:hover {
        ${StyledCheckbox} {
            ${({ disabled }) => getHoverStylesCheckbox(disabled)};
        }
    }
`;

export const CheckboxLabelText = styled(Typography).attrs({ variant: 'regular12' })`
    color: ${({ theme }) => theme.colors.grey1()};
`;
