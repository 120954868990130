import styled, { css } from 'styled-components';
import Typography from '@components/Typography';

export const imageStyles = css`
    width: 100%;
    ${({ theme }) => theme.mixins.flexCenter};
    background: ${({ theme }) => theme.colors.grey4()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.m}px;
    height: 481px;
    margin-bottom: 20px;

    @media ${({ theme }) => theme.breakpoints.md} {
        height: 224px;
        margin-bottom: 10px;
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const ImageButton = styled.button`
    ${imageStyles}
    padding: 0 34px;

    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 10px;
    }
`;

interface IImage {
    isLoaded: boolean;
}

export const Image = styled.img<IImage>`
    display: ${({ isLoaded }) => (isLoaded ? 'block' : 'none')};
    width: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;

    @media ${({ theme }) => theme.breakpoints.md} {
        max-height: 122px;
    }
`;

export const Content = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    justify-content: space-between;

    @media ${({ theme }) => theme.breakpoints.md} {
        align-items: center;
    }
`;

export const Description = styled.div`
    overflow: hidden;
`;

export const buttonAddToBasketStyles = css`
    width: 50px;
    height: 50px;
    border-radius: 50%;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 30px;
        height: 30px;
    }
`;

export const ButtonAddToBasket = styled.button`
    ${buttonAddToBasketStyles}
    flex-shrink: 0;
    background: ${({ theme }) => theme.colors.base()};
    ${({ theme }) => theme.mixins.flexCenterCenter};
    svg {
        transform: scale(1.3);
    }

    &:hover {
        background: ${({ theme }) => theme.colors.dark()};
    }

    &:active,
    &:focus {
        background: ${({ theme }) => theme.colors.dark3()};
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        svg {
            transform: scale(0.8);
        }
    }
`;

export const titleStyle = css`
    @media ${({ theme }) => theme.breakpoints.md} {
        display: none;
    }
`;

export const Title = styled(Typography).attrs({ variant: 'regular20' })`
    ${titleStyle}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Price = styled(Typography).attrs({ variant: 'regular16' })`
    color: ${({ theme }) => theme.colors.grey1()};

    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('regular14')}
    }
`;

export const MobileTitle = styled(Typography).attrs({ variant: 'regular12' })`
    display: none;

    @media ${({ theme }) => theme.breakpoints.md} {
        display: block;
        max-height: 32px;
        overflow: hidden;
    }
`;

export const productWrapperStyles = css`
    &:nth-child(5n-1) {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    @media ${({ theme }) => theme.breakpoints.ld} {
        &:nth-child(5n-1) {
            grid-column-start: auto;
            grid-column-end: auto;
        }

        &:nth-child(5n) {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }
`;

export const ProductWrapper = styled.div`
    ${productWrapperStyles}
    overflow: hidden;
    &:nth-child(5n-1) {
        ${ImageButton} {
            padding: 70px 80px;
        }
    }

    @media ${({ theme }) => theme.breakpoints.ld} {
        &:nth-child(5n-1) {
            ${ImageButton} {
                padding: 10px;
            }
        }

        &:nth-child(5n) {
            ${ImageButton} {
                padding: 70px 80px;
            }
        }
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        &:nth-child(5n) {
            ${ImageButton} {
                padding: 10px;
            }
        }
    }
`;
