import { RootState } from '@store';
import { claimAdapter } from '@store/Claim/index';
import { IClaim } from '@store/Claim/types';

const getClaims = (state: RootState) => state.claim;

const { selectAll } = claimAdapter.getSelectors(getClaims);

export const getClaimsList = (state: RootState) => selectAll(state);

export const getClaimByClaimIntegrationId = (claimIntegrationId: string) => (state: RootState) => {
    const claimsList: IClaim[] = getClaimsList(state) as any;
    return claimsList?.find((claim) => claim?.claimIntegrationId === claimIntegrationId);
};

export const getClaimIntegrationById = (claimIntegrationId: string) => (state: RootState) =>
    state.claim.claimIntegrations?.find((claimIntegration) => claimIntegration?.id === claimIntegrationId);
