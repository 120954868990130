import styled, { DefaultTheme } from 'styled-components';
import { COLOR_BRIGHTNESS } from '@theme/utils';
import { ButtonType } from '@components/Button/index';

export type StyledButtonType = ButtonType | 'linkWithIcon';

interface IButtonWrapperProps {
    $buttonType: StyledButtonType;
    $iconOnly: boolean;
    $hasIcon: boolean;
    disabled: boolean;
}

const isLinkButton = (buttonType: StyledButtonType) => buttonType === 'link';
const isLinkWithIconButton = (buttonType: StyledButtonType) => buttonType === 'linkWithIcon';
const isTextButton = (buttonType: StyledButtonType) =>
    isLinkButton(buttonType) || isLinkWithIconButton(buttonType) || false;

export const ButtonWrapper = styled('a')<IButtonWrapperProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    transition: ${({ theme }) => theme.decorations.transition?.base};
    border-radius: ${({ theme, $buttonType }) =>
        isLinkButton($buttonType) || $buttonType === 'dark' ? 0 : theme.decorations.borderRadius?.base}px;
    padding: ${({ $buttonType, $iconOnly, $hasIcon }) => getButtonPaddings($buttonType, $iconOnly, $hasIcon)};
    background-color: ${({ theme, $buttonType, disabled }) => typeToBg($buttonType, theme, disabled)};
    color: ${({ theme, $buttonType }) => typeToColor($buttonType, theme)};
    text-decoration: none;

    &:hover {
        opacity: ${() => COLOR_BRIGHTNESS.PRE_MEDIUM / 100};
        background-color: ${({ theme, $buttonType, disabled }) => typeToHoverBg($buttonType, theme, disabled)};
        color: ${({ theme, $buttonType }) => typeToColorHover($buttonType, theme)};
    }

    &:active,
    &:focus {
        background-color: ${({ theme, $buttonType, disabled }) => typeToActiveBg($buttonType, theme, disabled)};
        color: ${({ theme, $buttonType }) => typeToColorActive($buttonType, theme)};
    }

    &:disabled {
        opacity: 1;
        cursor: not-allowed;

        svg {
            opacity: ${({ $buttonType }) =>
                isLinkButton($buttonType) ? COLOR_BRIGHTNESS.LIGHT / 100 : COLOR_BRIGHTNESS.MEDIUM / 100};
        }
        color: ${({ theme, $buttonType }) => typeToColorDisabled($buttonType, theme)};
        background-color: ${({ theme, $buttonType }) => typeToDisableBg($buttonType, theme)};
    }
`;
interface IButtonInnerProps {
    iconOnly: boolean;
    isLoading: boolean;
}

export const ButtonInner = styled.span<IButtonInnerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};

    svg {
        margin-right: ${({ iconOnly }) => (iconOnly ? '0' : '9')}px;
        width: 24px;
        height: 24px;
    }
`;

const typeToBg = (buttonType: StyledButtonType, theme: DefaultTheme, disabled: boolean): string => {
    if (isTextButton(buttonType)) return 'transparent';
    if (disabled) return theme.colors.grey4();

    const hashMap = {
        primary: theme.colors.yellow(),
        secondary: theme.colors.grey4(),
        dark: theme.colors.base(),
        link: 'transparent',
        linkWithIcon: 'transparent',
    };

    return hashMap[buttonType];
};

const typeToHoverBg = (buttonType: StyledButtonType, theme: DefaultTheme, disabled: boolean): string => {
    if (isTextButton(buttonType)) return 'transparent';
    if (disabled) return theme.colors.grey4();

    const hashMap = {
        primary: theme.colors.hoverYellow(),
        secondary: theme.colors.grey5(),
        dark: theme.colors.dark(),
        link: 'transparent',
        linkWithIcon: 'transparent',
    };

    return hashMap[buttonType];
};

const typeToActiveBg = (buttonType: StyledButtonType, theme: DefaultTheme, disabled: boolean): string => {
    if (isTextButton(buttonType)) return 'transparent';
    if (disabled) return theme.colors.grey4();

    const hashMap = {
        primary: theme.colors.activeYellow(),
        secondary: theme.colors.grey4(),
        dark: theme.colors.dark3(),
        link: 'transparent',
        linkWithIcon: 'transparent',
    };

    return hashMap[buttonType];
};

const typeToDisableBg = (buttonType: StyledButtonType, theme: DefaultTheme): string => {
    if (isTextButton(buttonType)) return 'transparent';

    const hashMap = {
        primary: theme.colors.grey5(),
        secondary: theme.colors.grey5(),
        dark: theme.colors.grey2(),
        link: 'transparent',
        linkWithIcon: 'transparent',
    };

    return hashMap[buttonType];
};

const getButtonPaddings = (buttonType: StyledButtonType, iconOnly: boolean, hasIcon: boolean): string => {
    if (isTextButton(buttonType)) return '0px';

    if (iconOnly) {
        return '8px';
    } else {
        return hasIcon ? '15px 24px' : '12px 24px';
    }
};

const typeToColor = (buttonType: StyledButtonType, theme: DefaultTheme): string => {
    const hashMap = {
        primary: theme.colors.base(),
        secondary: theme.colors.base(),
        dark: theme.colors.white(),
        link: theme.colors.base(),
        linkWithIcon: theme.colors.base(),
    };

    return hashMap[buttonType];
};

const typeToColorHover = (buttonType: StyledButtonType, theme: DefaultTheme): string => {
    const hashMap = {
        primary: theme.colors.base(),
        secondary: theme.colors.base(),
        dark: theme.colors.white(),
        link: theme.colors.dark(),
        linkWithIcon: theme.colors.dark(),
    };

    return hashMap[buttonType];
};

const typeToColorActive = (buttonType: StyledButtonType, theme: DefaultTheme): string => {
    const hashMap = {
        primary: theme.colors.base(),
        secondary: theme.colors.base(),
        dark: theme.colors.white(),
        link: theme.colors.dark3(),
        linkWithIcon: theme.colors.dark3(),
    };

    return hashMap[buttonType];
};

const typeToColorDisabled = (buttonType: StyledButtonType, theme: DefaultTheme): string => {
    const hashMap = {
        primary: theme.colors.grey2(),
        secondary: theme.colors.grey2(),
        dark: theme.colors.white(),
        link: theme.colors.grey2(),
        linkWithIcon: theme.colors.grey2(),
    };

    return hashMap[buttonType];
};
