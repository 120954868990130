import React, { FC, useEffect } from 'react';
import useToast from '@hooks/useToast';
import Toast from '@components/Toast';
import { useSelector } from 'react-redux';
import { getErrorCode } from '@store/System/selectors';
import { useAppDispatch } from '@store';
import { clearErrorCode } from '@store/System';
import { useIntl } from 'react-intl';
import { LayoutWrapper, PageContainer } from './styles';
import Header from './components/Header';
import Footer from './components/Footer';
import Meta from './components/Meta';
import { enumErrorCode, errorLabel } from './types';

const MainLayout: FC = ({ children }) => {
    const intl = useIntl();
    const { isShowToast, openToast, hideToast } = useToast();
    const dispatch = useAppDispatch();

    const errorCode: enumErrorCode = useSelector(getErrorCode) as any;

    const afterCloseToast = () => {
        dispatch(clearErrorCode());
    };

    useEffect(() => {
        if (errorCode) {
            openToast();
        }
    }, [errorCode, openToast]);

    return (
        <LayoutWrapper>
            <Toast
                afterCloseToast={afterCloseToast}
                isShowToast={isShowToast}
                title={errorCode ? errorLabel(intl, errorCode) : null}
                hideToast={hideToast}
            />
            <Meta />
            <Header />
            <PageContainer>{children}</PageContainer>
            <Footer />
        </LayoutWrapper>
    );
};

export default MainLayout;
