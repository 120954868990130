import React, { FC } from 'react';
import {
    BasketItemContent,
    BasketItemFooter,
    BasketItemImage,
    BasketItemTitle,
    BasketItemWrapper,
    ImageWrapper,
} from '@pages/Payment/components/BasketItem/styles';
import { convertCurrencyToShow, getImageURL } from '@utils/helpers';
import { IGood } from '@store/Good/types';
import { useSelector } from 'react-redux';
import { getCountItemById } from '@store/Cart/selectors';

interface IBasketItem {
    good: IGood;
}

const BasketItem: FC<IBasketItem> = ({ good }) => {
    const countGood = useSelector(getCountItemById(good.id));

    return (
        <BasketItemWrapper>
            <ImageWrapper>
                <BasketItemImage src={getImageURL(good.imagePath)} />
            </ImageWrapper>
            <BasketItemContent>
                <BasketItemTitle>{good.title}</BasketItemTitle>
                <BasketItemFooter>{`${countGood} x ${convertCurrencyToShow(good.price)}`}</BasketItemFooter>
            </BasketItemContent>
        </BasketItemWrapper>
    );
};

export default BasketItem;
