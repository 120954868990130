import * as yup from 'yup';
import { IntlShape } from 'react-intl';

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'isValidPhone', function (intl: IntlShape) {
    // eslint-disable-next-line func-names
    return this.test('isValidPhone', '', function (value: string = '') {
        if (value === '') {
            return true;
        }
        const { createError, path } = this as yup.TestContext;
        const valueWithoutNumbers = value.replace(/\d/gm, '');
        if (value.length - valueWithoutNumbers.length === 11) {
            return true;
        }
        return createError({ path, message: intl.formatMessage({ defaultMessage: 'Некорректный номер' }) });
    });
});

const checkDigitFunc = (inn: string, coefficients: number[]) => {
    let n = 0;
    coefficients.forEach((element, i) => {
        n += element * Number(inn[i]);
    });

    return (n % 11) % 10;
};

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'isValidInn', function (intl: IntlShape) {
    // eslint-disable-next-line func-names
    return this.test('isValidInn', '', function (value: string = '') {
        const { createError, path } = this as yup.TestContext;

        if ([10, 12].indexOf(value.length) === -1) {
            return createError({
                path,
                message: intl.formatMessage({ defaultMessage: 'ИНН должен состоять из 10 или 12 цифр' }),
            });
        } else {
            switch (value.length) {
                case 10: {
                    const n10 = checkDigitFunc(value, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
                    if (n10 === parseInt(value[9], 10)) {
                        return true;
                    }
                    break;
                }
                case 12: {
                    const n11 = checkDigitFunc(value, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                    const n12 = checkDigitFunc(value, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                    if (n11 === parseInt(value[10], 10) && n12 === parseInt(value[11], 10)) {
                        return true;
                    }
                    break;
                }
                default:
                    return createError({
                        path,
                        message: intl.formatMessage({ defaultMessage: 'Неверное контрольное число' }),
                    });
            }
        }
        return createError({ path, message: intl.formatMessage({ defaultMessage: 'Неверное контрольное число' }) });
    });
});

export default yup;
