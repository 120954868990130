import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { IGood } from '@store/Good/types';
import createAsyncThunkWithErrorHandler from '@utils/helpers/createAsyncThunkWithErrorHandler';
import { getGoodsRequest } from './http';

export const fetchGoods = createAsyncThunkWithErrorHandler('good/getAll', async () => {
    const { data } = await getGoodsRequest();
    return data;
});

export const goodAdapter = createEntityAdapter<IGood>();

const initialState = goodAdapter.getInitialState({
    isLoading: true,
    metaData: {
        totalCount: 0,
        pageSize: 0,
        page: 0,
    },
});

const good = createSlice({
    name: 'good',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchGoods.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchGoods.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.metaData = payload.metaData;
            goodAdapter.setAll(state, payload.data);
        });
        builder.addCase(fetchGoods.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export default good.reducer;
