import React, { FC } from 'react';
import {
    BasketItem,
    BasketItemImage,
    BasketItemImageWrapper,
    BasketItemName,
    BasketItemPrice,
    BasketItems,
    ButtonAction,
    ButtonConnect,
    DetailOrderSubtitle,
    DetailOrderTitle,
    DetailPaymentContainer,
    DetailPaymentWrapper,
    ShopImage,
    TotalPrice,
    TotalPriceLabel,
    TotalPriceValue,
} from '@pages/Payment/components/DetailPayment/styles';
import { useParams } from 'react-router-dom';
import { IGood } from '@store/Good/types';
import { TCartItems } from '@store/Cart/types';
import { actionPayment, convertCurrencyToShow, getImageURL } from '@utils/helpers';
import { useSelector } from 'react-redux';
import { getPaymentMethodById } from '@store/PaymentMethods/selectors';
import { enumExternalId } from '@store/PaymentMethods/types';
import InvoiceServices from '@pages/Payment/components/InvoiceServices';
import useModal from '@hooks/useModal';
import ModalClaimConnection from '@components/ModalClaimConnection';
import { IStep } from '@pages/Payment/types';
import { useIntl } from 'react-intl';

interface IGoodWithCount extends IGood {
    count: number;
}

const DetailPayment: FC<IStep> = ({ order, cart, paymentAction }) => {
    const intl = useIntl();
    const { isOpen: isOpenModalConnection, open: openModalConnection, close: onCloseModalConnection } = useModal(false);
    const { orderId } = useParams<{ orderId: string }>();

    const isInvoicePaymentType =
        useSelector(getPaymentMethodById(order?.shopPaymentId || -1))?.externalId === enumExternalId.invoicing;

    const goodsWithCount: IGoodWithCount[] = cart?.goods?.map((good: IGood) => ({
        ...good,
        count: cart?.items?.find((cartItem: TCartItems) => cartItem?.goodId === good?.id)?.count,
    })) as any;

    const pay = () => {
        if (paymentAction) {
            actionPayment(paymentAction);
        }
    };

    return (
        <>
            <DetailPaymentWrapper>
                <DetailPaymentContainer>
                    <ShopImage />
                    <DetailOrderTitle>{intl.formatMessage({ defaultMessage: 'Спасибо за заказ!' })}</DetailOrderTitle>
                    <DetailOrderSubtitle>{`${intl.formatMessage({
                        defaultMessage: 'Заказ',
                        description: 'Заказ x успешно сформирован',
                    })} №${orderId} ${intl.formatMessage({
                        defaultMessage: 'успешно сформирован',
                        description: 'Заказ x успешно сформирован',
                    })}${
                        isInvoicePaymentType
                            ? `, ${intl.formatMessage({
                                  defaultMessage: 'счёт отправлен на электронную почту',
                                  description: 'Заказ x успешно сформирован, счёт отправлен на электронную почту',
                              })}`
                            : ''
                    } `}</DetailOrderSubtitle>
                    <BasketItems>
                        {goodsWithCount?.map((good) => (
                            <BasketItem key={good.id}>
                                <BasketItemImageWrapper>
                                    <BasketItemImage src={getImageURL(good.imagePath)} />
                                </BasketItemImageWrapper>
                                <BasketItemName>{good.title}</BasketItemName>
                                <BasketItemPrice>{`${good.count} x ${convertCurrencyToShow(
                                    good.price,
                                )}`}</BasketItemPrice>
                            </BasketItem>
                        ))}
                    </BasketItems>
                    <TotalPrice>
                        <TotalPriceLabel>
                            {intl.formatMessage({ defaultMessage: 'Итоговая стоимость:' })}
                        </TotalPriceLabel>
                        <TotalPriceValue>
                            {cart?.totalAmount && convertCurrencyToShow(cart.totalAmount)}
                        </TotalPriceValue>
                    </TotalPrice>
                    <ButtonAction type="dark" onClick={pay}>
                        {intl.formatMessage({ defaultMessage: 'Скачать и оплатить счёт' })}
                    </ButtonAction>
                </DetailPaymentContainer>
                {isInvoicePaymentType && <InvoiceServices />}
            </DetailPaymentWrapper>
            <ButtonConnect onClick={openModalConnection}>
                {intl.formatMessage({ defaultMessage: 'Оставить заявку на подключение' })}
            </ButtonConnect>
            <ModalClaimConnection closeModal={onCloseModalConnection} isOpen={isOpenModalConnection} />
        </>
    );
};

export default DetailPayment;
