import styled from 'styled-components';
import Typography from '@components/Typography';

export const PaymentMethodWrapper = styled.div`
    padding: 50px 0;
    border: 1px solid ${({ theme }) => theme.colors.grey3()};
    border-right: none;
    border-left: none;
    margin: 28px 0 50px 0;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 28px 0;
        margin: 28px 0;
    }
`;

export const PaymentTitle = styled(Typography).attrs({ variant: 'bold34' })`
    margin-bottom: 40px;
    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('bold24')}
        margin-bottom: 20px;
    }
`;

export const PaymentButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    @media ${({ theme }) => theme.breakpoints.xl} {
        grid-template-columns: 1fr;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-gap: 12px;
    }
`;
