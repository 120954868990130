import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '@store/Auth/types';
import { Nullable } from '@utils/utilityTypes';
import createAsyncThunkWithErrorHandler from '@utils/helpers/createAsyncThunkWithErrorHandler';
import { getUserProfileRequest } from './http';

export const fetchUserProfile = createAsyncThunkWithErrorHandler('auth/profile/current', async () => {
    const { data } = await getUserProfileRequest().then((res) => res.data);
    return data;
});

interface IAuthState {
    user: Nullable<IUser>;
    loading: boolean;
}

const initialState = {
    user: null,
    loading: false,
} as IAuthState;

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserProfile.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserProfile.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.user = payload;
        });
        builder.addCase(fetchUserProfile.rejected, (state) => {
            state.loading = false;
        });
    },
});

export default auth.reducer;
