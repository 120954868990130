import { DefaultTheme } from 'styled-components';
import { kebabCase, mapKeys, mapValues } from 'lodash';
import { pipe } from '@utils/pipe';
import typography, { TTypography, TTypographyItem } from './typography';

const getBorderColorInputToHover = (theme: DefaultTheme, isRounded: boolean, hasError: boolean) => {
    if (hasError) {
        return theme.colors.red();
    }
    if (!isRounded) {
        return theme.colors.grey2();
    }
    return 'transparent';
};

const getBorderColorInputContainer = (theme: DefaultTheme, hasError: boolean, inFocus: boolean, isRounded: boolean) => {
    if (hasError) {
        return theme.colors.red();
    }
    if (!isRounded) {
        if (inFocus) {
            return theme.colors.grey2();
        }
        return theme.colors.grey3();
    } else {
        return 'transparent';
    }
};

const getBgColorInputContainer = (theme: DefaultTheme, inFocus: boolean, hasError: boolean, isRounded: boolean) => {
    if (!isRounded) {
        return theme.colors.white();
    }
    if (hasError || inFocus) {
        return theme.colors.grey4();
    }
    return theme.colors.grey5();
};

const getFieldLabelColor = (theme: DefaultTheme, inFocus: boolean, hasError: boolean): string => {
    if (hasError) {
        return theme.colors.red();
    }
    if (inFocus) {
        return theme.colors.base();
    }
    return theme.colors.grey1();
};

// getTypography helpers start =========
const typographyPixelKeys = ['fontSize', 'lineHeight'];

const pixelizeValues = (values: TTypographyItem) =>
    mapValues(values, (v, k) => (typographyPixelKeys.includes(k) ? `${v}px` : v?.toString()));

const camelToKebabKeys = (obj: Object) => mapKeys(obj, (v, k) => kebabCase(k));
// getTypography helpers end =========

const getTypography = (typographyType: keyof TTypography) =>
    pipe(typography[typographyType], pixelizeValues, camelToKebabKeys);

export default {
    getBorderColorInputContainer,
    getBgColorInputContainer,
    getFieldLabelColor,
    getTypography,
    getBorderColorInputToHover,
};
