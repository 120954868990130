import React, { FC } from 'react';
import { HelperDescription, HelperNumber, PaymentHelper } from '@pages/Payment/components/HelperAddingItems/styles';
import { IHelper } from '@pages/Payment/types';
import { useIntl } from 'react-intl';

const HelperPayingCard: FC<IHelper> = ({ isPaymentCard }) => {
    const intl = useIntl();

    const helperDescriptions = [
        {
            id: 1,
            value: isPaymentCard
                ? intl.formatMessage({
                      defaultMessage:
                          'Пожалуйста, внесите в форму контактные данные, на которые мы отправим информацию о заказе и электронный чек',
                  })
                : intl.formatMessage({
                      defaultMessage:
                          'Пожалуйста, внесите в форму реквизиты организации-плательщика и контактные данные. Мы отправим информацию о заказе, а также счёт на оплату',
                  }),
        },
        {
            id: 2,
            value: intl.formatMessage({ defaultMessage: 'Перейдите к оплате' }),
        },
    ];

    return (
        <PaymentHelper>
            {helperDescriptions.map((helperRow) => (
                <HelperDescription key={helperRow.id}>
                    <HelperNumber>{helperRow.id}.</HelperNumber>
                    {helperRow.value}
                </HelperDescription>
            ))}
        </PaymentHelper>
    );
};

export default HelperPayingCard;
