import React from 'react';
import { ThemeProvider } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';
import Pages from '@pages/index';
import { Provider } from 'react-redux';
import theme from './theme';
import GlobalStyle from './globalStyles/GlobalStyle';
import store from './store';

const App = () => (
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <HelmetProvider>
                <Pages />
            </HelmetProvider>
        </ThemeProvider>
    </Provider>
);
export default App;
