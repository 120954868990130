import { createPalette } from './utils';

export default createPalette({
    dark: '#21262A',
    base: '#353F47',
    dark3: '#333A40',
    white: '#FFFFFF',
    darkest: '#202932',

    yellow: '#FAD247',
    hoverYellow: '#F1CD50',
    activeYellow: '#EDC743',

    grey1: '#878B8E',
    grey2: '#B8B9BC',
    grey3: '#D4D5DC',
    grey4: '#F1F2F6',
    grey5: '#F8F8FA',
    grey: '#999999',

    red: '#EB5757',
    green: '#9DDD4D',
});
