import RusFlag from '@assets/icons/rus.svg';
import UsaFlag from '@assets/icons/usa.svg';
import React from 'react';
import { IntlShape } from 'react-intl';

export const localStorageLanguageKey = 'localeLanguage';

export enum enumLanguage {
    ru = 'ru',
    en = 'en',
}

export const titleLanguage = {
    [enumLanguage.ru]: 'Рус',
    [enumLanguage.en]: 'Eng',
};

export const languageFlags = {
    [enumLanguage.ru]: <RusFlag />,
    [enumLanguage.en]: <UsaFlag />,
};

export enum enumErrorCode {
    internetConnection = 'internetConnection',
    XHR = 'XHR',
}

export const errorLabel = (intl: IntlShape, errorCode: enumErrorCode) => {
    const errorLabels = {
        [enumErrorCode.internetConnection]: intl.formatMessage({ defaultMessage: 'Проблемы с интернет соединением' }),
        [enumErrorCode.XHR]: intl.formatMessage({ defaultMessage: 'Ошибка сети' }),
    };
    return errorLabels[errorCode];
};
