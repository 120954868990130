import React, { FC } from 'react';
import decorations from '@theme/decorations';
import { SkeletonBody, SkeletonWrapper } from '@components/Skeleton/styles';

interface ISkeletonProps {
    circle?: boolean;
    width?: number;
    height?: number;
    className?: string;
    borderRadius?: keyof typeof decorations.borderRadius;
}

const Skeleton: FC<ISkeletonProps> = ({ height, width, borderRadius = 'm', className, circle = false }) => (
    <SkeletonWrapper className={className}>
        <SkeletonBody width={width} height={height} borderRadius={borderRadius} circle={circle} />
    </SkeletonWrapper>
);

export default Skeleton;
