import { STATIC_BASE_URL } from '@utils/http';
import { enumTypesPaymentAction, IPaymentAction } from '@store/PaymentMethods/types';
import { saveAs } from 'file-saver';
import { linkDemoStandProd, linkDemoStandStage } from '@utils/constants';
import { enumTypeEnvironment } from '@store/System/types';

const numberWithSpaces = (number: number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const convertCurrencyToShow = (currency: number) => `${numberWithSpaces(currency).split('.').join(',')} ₽`;

export const getImageURL = (imagePath: string) => `${STATIC_BASE_URL}/${imagePath}`;

export const createHiddenForm = (paymentAction: IPaymentAction) => {
    const form = document.createElement('form');
    form.style.display = 'none';
    form.action = paymentAction.url;
    form.method = 'POST';
    const arrHiddenArguments = Object.entries(paymentAction.args).map((item) => ({
        name: item[0],
        value: item[1],
    }));

    form.innerHTML = arrHiddenArguments
        .map((argument) => `<input name=${argument.name} value=${argument.value}>`)
        .join(' ');
    document.body.append(form);
    form.submit();
};

export const actionPayment = (paymentAction: IPaymentAction) => {
    switch (paymentAction.type) {
        case enumTypesPaymentAction.submit: {
            createHiddenForm(paymentAction);
            break;
        }
        case enumTypesPaymentAction.redirect: {
            window.open(paymentAction.url);
            break;
        }
        case enumTypesPaymentAction.download: {
            saveAs(paymentAction.url);
            break;
        }
        case enumTypesPaymentAction.none: {
            break;
        }
        default:
            break;
    }
};

export const getEnvironmentByURL = (currentURL: string) => {
    if (currentURL.includes(linkDemoStandProd)) {
        return enumTypeEnvironment.production;
    }
    if (currentURL.includes(linkDemoStandStage)) {
        return enumTypeEnvironment.staging;
    }
    return enumTypeEnvironment.develop;
};
