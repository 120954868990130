import React from 'react';
import {
    BannerDescription,
    BannerHead,
    BannerTitle,
    BannerWrapper,
    Content,
    LinkToApp,
    LogoWrapper,
    QRCode,
    QrWRapper,
    StoresApps,
} from '@pages/Main/components/AppBanner/styles';
import appStoreRU from '@assets/icons/appStoreRU.svg';
import googlePlayRU from '@assets/icons/googlePlayRU.svg';
import appStoreEN from '@assets/icons/appStoreEN.svg';
import googlePlayEN from '@assets/icons/googlePlayEN.svg';
import { appStoreAppLink, playMarketAppLink } from '@utils/constants';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getLanguage } from '@store/System/selectors';
import logoLightRU from '@assets/icons/logoLightRU.svg';
import logoLightEN from '@assets/icons/logoLightEN.svg';
import { enumLanguage } from '../../../../layouts/Main/types';

export const logoLocale = {
    [enumLanguage.ru]: logoLightRU,
    [enumLanguage.en]: logoLightEN,
};

export const iconsShopApps = {
    [enumLanguage.ru]: {
        appStore: appStoreRU,
        googlePlay: googlePlayRU,
    },
    [enumLanguage.en]: {
        appStore: appStoreEN,
        googlePlay: googlePlayEN,
    },
};

const AppBanner = () => {
    const intl = useIntl();

    const language = useSelector(getLanguage);

    const Logo = logoLocale[language];
    const LogoAppStore = iconsShopApps[language].appStore;
    const LogoGooglePlay = iconsShopApps[language].googlePlay;

    return (
        <BannerWrapper>
            <QrWRapper>
                <QRCode />
            </QrWRapper>
            <Content>
                <BannerHead>
                    <BannerTitle>{intl.formatMessage({ defaultMessage: 'Приложение' })}</BannerTitle>
                    <LogoWrapper>
                        <Logo />
                    </LogoWrapper>
                </BannerHead>
                <BannerDescription>
                    {intl.formatMessage({
                        defaultMessage:
                            'Наше приложение доступно в RuStore, NashStore, AppStore, Google Play и App Gallery для всех Ваших мобильных устройств: телефонов и планшетов. Всё самое важное всегда под рукой! Наведите камеру на QR-код для загрузки приложения',
                    })}
                </BannerDescription>
                <StoresApps>
                    <LinkToApp target="_blank" href={appStoreAppLink}>
                        <LogoAppStore />
                    </LinkToApp>
                    <LinkToApp target="_blank" href={playMarketAppLink}>
                        <LogoGooglePlay />
                    </LinkToApp>
                </StoresApps>
            </Content>
        </BannerWrapper>
    );
};

export default AppBanner;
