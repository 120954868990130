import React, { FC } from 'react';
import { SpinnerImage } from '@components/LoadingSpinner/styles';

interface ILoadingSpinner {
    className?: string;
}

const LoadingSpinner: FC<ILoadingSpinner> = ({ className }) => <SpinnerImage className={className} />;

export default LoadingSpinner;
