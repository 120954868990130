import React from 'react';
import { Field } from 'react-final-form';
import Input, { IInputProps } from '@components/Input';
import Checkbox from '@components/Checkbox';

// eslint-disable-next-line no-shadow
export enum FormFieldTypeEnum {
    input = 'input',
    checkbox = 'checkbox',
}

interface IFormFieldProps extends IInputProps {
    name: string;
    fieldType?: FormFieldTypeEnum;
    parse?: (value: string) => string;
    format?: (value: string) => string;
}

const FieldsMap = {
    [FormFieldTypeEnum.input]: Input,
    [FormFieldTypeEnum.checkbox]: Checkbox,
};

// eslint-disable-next-line react/function-component-definition
function FormField<T = IInputProps>({
    name,
    parse,
    format,
    fieldType = FormFieldTypeEnum.input,
    ...inputProps
}: T & IFormFieldProps) {
    // @ts-ignore
    const FieldComponent = FieldsMap[fieldType];

    return (
        <Field name={name} allowNull={false} type={inputProps.type} parse={parse} format={format}>
            {({ input, meta: { invalid, touched, error } }) => (
                // @ts-ignore
                <FieldComponent {...input} hasError={touched && invalid} error={error} {...inputProps} />
            )}
        </Field>
    );
}

export default React.memo(FormField);
