import { IClaimIntegration, IClaimRequestData, IExtendedData } from '@store/Claim/types';
import { Nullable } from '@utils/utilityTypes';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import createAsyncThunkWithErrorHandler from '@utils/helpers/createAsyncThunkWithErrorHandler';
import { createClaimRequest, fetchClaimsRequest } from '@store/Claim/http';

export const fetchClaims = createAsyncThunkWithErrorHandler('claims/getAll', async (arg, thunkAPI) => {
    const { data } = await fetchClaimsRequest();
    const claimIntegrations = data?.extendedData?.find(
        (extendData: IExtendedData<IClaimIntegration>) => extendData?.entity === 'claim-integration',
    )?.data;
    if (claimIntegrations) {
        thunkAPI.dispatch(setClaimIntegrations(claimIntegrations));
    }
    return data?.data;
});

export const createClaim = createAsyncThunkWithErrorHandler('claims/create', async (claimData: IClaimRequestData) => {
    const { data } = await createClaimRequest(claimData);
    return data?.data;
});

interface IClaimStore {
    claimIntegrations: Nullable<IClaimIntegration[]>;
    isLoading: boolean;
}

export const claimAdapter = createEntityAdapter();

const initialState = claimAdapter.getInitialState({
    claimIntegrations: null,
    isLoading: false,
} as IClaimStore);

const claim = createSlice({
    name: 'claim',
    initialState,
    reducers: {
        setClaimIntegrations: (state, { payload }) => {
            state.claimIntegrations = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchClaims.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchClaims.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            claimAdapter.upsertMany(state, payload);
        });
        builder.addCase(fetchClaims.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { setClaimIntegrations } = claim.actions;

export default claim.reducer;
