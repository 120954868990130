import { useState } from 'react';

const useToast = () => {
    const [isShowToast, setIsShowToast] = useState(false);

    const openToast = () => setIsShowToast(true);
    const hideToast = () => setIsShowToast(false);

    return { isShowToast, openToast, hideToast, setIsShowToast };
};

export default useToast;
