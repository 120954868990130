import React, { useState } from 'react';
import {
    ButtonService,
    ButtonWrapper,
    InvoiceServicesWrapper,
    InvoiceTitle,
    PromptButton,
    Service,
    ServiceDescription,
    ServicesWrapper,
    ServiceTitle,
} from '@pages/Payment/components/InvoiceServices/styles';
import ModalDelayPay, { enumTypeDelay } from '@components/ModalDelayPay';
import useModal from '@hooks/useModal';
import QuestionIcon from '@assets/icons/question.svg';
import { useIntl } from 'react-intl';

const InvoiceServices = () => {
    const intl = useIntl();
    const { isOpen: isOpenModalDelay, open: openModalDelay, close: onCloseModalDelay } = useModal(false);
    const [typeDelay, setTypeDelay] = useState<enumTypeDelay>(enumTypeDelay.fourDays);

    const services = [
        {
            title: intl.formatMessage({ defaultMessage: 'Отсрочка платежа до 4 дней' }),
            description: intl.formatMessage({
                defaultMessage:
                    'Использование временной гарантийной блокировки средств в размере суммы заказа на банковской карте',
            }),
            button: {
                title: intl.formatMessage({ defaultMessage: 'Гарантийная блокировка средств' }),
            },
            typeDelay: enumTypeDelay.fourDays,
        },
        {
            title: intl.formatMessage({ defaultMessage: 'Отсрочка платежа до 30 дней' }),
            description: intl.formatMessage({ defaultMessage: 'Доступно для пользователей системы Инвойсбокс.Бизнес' }),
            button: {
                title: intl.formatMessage({ defaultMessage: 'Войти в Инвойсбокс.Бизнес' }),
            },
            typeDelay: enumTypeDelay.thirtyDays,
        },
    ];

    return (
        <InvoiceServicesWrapper>
            <InvoiceTitle>{intl.formatMessage({ defaultMessage: 'Получить заказ до оплаты счёта' })}</InvoiceTitle>
            <ServicesWrapper>
                {services.map((service) => (
                    <Service key={service.title}>
                        <ServiceTitle>{service.title}</ServiceTitle>
                        <ServiceDescription>{service.description}</ServiceDescription>
                        <ButtonWrapper>
                            <PromptButton
                                onClick={() => {
                                    setTypeDelay(service.typeDelay);
                                    openModalDelay();
                                }}
                            >
                                <QuestionIcon />
                            </PromptButton>
                            <ButtonService type="dark" disabled>
                                {service.button.title}
                            </ButtonService>
                        </ButtonWrapper>
                    </Service>
                ))}
            </ServicesWrapper>
            <ModalDelayPay typeDelay={typeDelay} isOpen={isOpenModalDelay} closeModal={onCloseModalDelay} />
        </InvoiceServicesWrapper>
    );
};

export default InvoiceServices;
