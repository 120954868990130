import http, { ICommonResponse } from '@utils/http';
import qs from 'query-string';
import qs1 from 'qs';
import useSWROriginal from 'swr';

const defaultFetcher = (url: string) => http.get(url).then((res) => res.data);

const getUrlWithParams = (url: string | null, params?: any) => {
    if (url === null) return url;
    const parsedUrl = qs.parseUrl(url);
    // @ts-ignore
    parsedUrl.query = { ...parsedUrl.query, ...params };
    return `${parsedUrl.url}?${qs1.stringify(parsedUrl.query, { arrayFormat: 'brackets', encodeValuesOnly: true })}`;
};

// @ts-ignore
export const useSWR = <T>(url: string | null, params?: any, isCanFetch?: boolean = true) =>
    useSWROriginal<ICommonResponse<T>>(isCanFetch ? getUrlWithParams(url, params) : null, defaultFetcher, {
        errorRetryCount: 3,
    });
