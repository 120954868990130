import React, { FC } from 'react';
import { FlagWrapper, LanguageItemButton, LanguageTitle } from './styles';
import { enumLanguage, languageFlags, titleLanguage } from '../../types';

interface ILanguageItem {
    language: string;
    isSelected: boolean;
    setSelectedLanguage: (arg: enumLanguage) => void;
    onClickHideLanguageList: () => void;
}

const LanguageItem: FC<ILanguageItem> = ({ onClickHideLanguageList, setSelectedLanguage, isSelected, language }) => {
    const currentEnumLanguage: enumLanguage = language as any;

    const onClickItemLanguage = () => {
        setSelectedLanguage(currentEnumLanguage);
        onClickHideLanguageList();
    };

    return (
        <LanguageItemButton type="button" onClick={onClickItemLanguage}>
            <FlagWrapper isSelected={isSelected}>{languageFlags[currentEnumLanguage]}</FlagWrapper>
            <LanguageTitle>{titleLanguage[currentEnumLanguage]}</LanguageTitle>
        </LanguageItemButton>
    );
};

export default LanguageItem;
