import React, { FC } from 'react';
import { Form } from 'react-final-form';
import {
    ConsultationSubtitle,
    ConsultationTitle,
    InputsWrapper,
    ModalConsultationWrapper,
    SubmitButton,
} from '@pages/Main/components/ModalConsultation/styles';
import useValidationSchema from '@hooks/useValidationSchema';
import { consultationSchema } from '@pages/Main/validations';
import useLoadingSubmit from '@hooks/useLoadingSubmit';
import { useIntl } from 'react-intl';
import { useAppDispatch } from '@store';
import GeneratorInputsForm from '@components/GeneratorInputsForm';
import { useSelector } from 'react-redux';
import { getClaimByClaimIntegrationId, getClaimIntegrationById } from '@store/Claim/selectors';
import { IClaim, IClaimIntegration, INTEGRATION_FIELDS } from '@store/Claim/types';
import { createClaim } from '@store/Claim';
import { getShopId } from '@store/System/selectors';
import { removePlusAndGapsToPhone } from '@utils/formatters';

interface IModalConsultation {
    isOpen: boolean;
    closeModal: () => void;
}

const ModalConsultation: FC<IModalConsultation> = ({ isOpen, closeModal }) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const typeRequest = 'feedback';

    const claim: IClaim = useSelector(getClaimByClaimIntegrationId(typeRequest)) as any;
    const claimIntegration: IClaimIntegration = useSelector(getClaimIntegrationById(typeRequest)) as any;
    const shopId: number = useSelector(getShopId) as any;

    const properties = claimIntegration?.[INTEGRATION_FIELDS.fields]?.[INTEGRATION_FIELDS.properties];

    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();
    const validation = useValidationSchema(
        consultationSchema(
            intl,
            properties && Object.keys(properties),
            claimIntegration?.[INTEGRATION_FIELDS.fields]?.[INTEGRATION_FIELDS.required],
        ),
    );

    const onSubmit = async (formValues: { [key: string]: string }) => {
        toggleLoadingSubmit();

        const { payload } = await dispatch(
            createClaim({
                shopClaimId: claim?.id,
                shopId,
                fields: { ...formValues, phone: formValues?.phone && removePlusAndGapsToPhone(formValues?.phone) },
            }),
        );
        toggleLoadingSubmit();

        if (payload?.shopId) {
            closeModal();
        }
    };

    return (
        <ModalConsultationWrapper closeModal={closeModal} isOpen={isOpen}>
            <ConsultationTitle>{intl.formatMessage({ defaultMessage: 'Заявка на консультацию' })}</ConsultationTitle>
            <ConsultationSubtitle>
                {intl.formatMessage({
                    defaultMessage:
                        'Чтобы узнать больше о сервисе приёма платежей, заполните заявку на получение консультации от наших специалистов',
                })}
            </ConsultationSubtitle>
            <Form validate={validation} onSubmit={onSubmit}>
                {({ handleSubmit, valid }) => (
                    <form>
                        <InputsWrapper>
                            <GeneratorInputsForm properties={properties} />
                        </InputsWrapper>
                        <SubmitButton isLoading={isLoadingSubmit} disabled={!valid} onClick={handleSubmit}>
                            {intl.formatMessage({ defaultMessage: 'Отправить заявку' })}
                        </SubmitButton>
                    </form>
                )}
            </Form>
        </ModalConsultationWrapper>
    );
};

export default ModalConsultation;
