import { IOrderResponse } from '@store/Order/types';
import { TCart } from '@store/Cart/types';
import { enumExternalId, IPaymentAction } from '@store/PaymentMethods/types';
import { Routes } from '@pages/constants';
import {
    invoiceBoxURLEN,
    invoiceBoxURLRU,
    personalDataProcessingPoliceEN,
    personalDataProcessingPoliceRU,
} from '@utils/constants';
import { enumLanguage } from '../../layouts/Main/types';

export enum enumTypePayment {
    paymentCard = Routes.paymentCard,
    paymentAccount = Routes.paymentAccount,
    paymentInternetBank = Routes.paymentInternetBank,
    paymentAny = Routes.paymentAny,
}

export interface IStep {
    goToNextStep: () => void;
    goToPrevStep: () => void;
    currentTypePayment: enumTypePayment;
    // props for last step
    order?: IOrderResponse;
    cart?: TCart;
    paymentAction?: IPaymentAction;
    typePaymentKeep: { [p: number]: { title: string; external: enumExternalId[] } };
}

export enum stepsPayment {
    addingItems,
    payment,
    successPayment,
}

export interface IHelper {
    goToNextStep: () => void;
    isPaymentCard: boolean;
}

export const personalDataProcessingPolice = {
    [enumLanguage.ru]: personalDataProcessingPoliceRU,
    [enumLanguage.en]: personalDataProcessingPoliceEN,
};

export const invoiceBoxURL = {
    [enumLanguage.ru]: invoiceBoxURLRU,
    [enumLanguage.en]: invoiceBoxURLEN,
};
