import styled from 'styled-components';
import Button from '@components/Button';
import ShopIcon from '@assets/icons/shopLogo.svg';
import Typography from '@components/Typography';

export const DetailPaymentWrapper = styled.div`
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    padding-top: 44px;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding-top: 30px;
    }
`;

export const DetailPaymentContainer = styled.div`
    max-width: 503px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 68px;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 0 10px 35px 10px;
    }
`;

export const ButtonConnect = styled(Button)`
    margin: 70px auto 0;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 46px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 100%;
    }
`;

export const ShopImage = styled(ShopIcon)`
    display: block;
    margin: 0 auto 58px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 38px;
    }
`;

export const DetailOrderTitle = styled(Typography).attrs({ variant: 'bold24' })`
    margin-bottom: 14px;
    text-align: center;
    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('bold20')}
    }
`;

export const DetailOrderSubtitle = styled(Typography).attrs({ variant: 'regular24' })`
    color: ${({ theme }) => theme.colors.grey1()};
    text-align: center;
    margin-bottom: 40px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 30px;
    }
`;

export const BasketItems = styled.div`
    margin: 0 auto;
    padding-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey3()};
    display: grid;
    grid-row-gap: 13px;
    max-width: 430px;

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row-gap: 16px;
    }
`;

export const BasketItem = styled.div`
    display: grid;
    grid-template-columns: 45px 1fr 100px;
    grid-column-gap: 18px;
    align-items: center;
`;

export const BasketItemImageWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    width: 45px;
    height: 45px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.m}px;
    background: ${({ theme }) => theme.colors.grey4()};
    padding: 8px;
`;

export const BasketItemName = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey1()};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const BasketItemPrice = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey1()};
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
`;

export const BasketItemImage = styled.img`
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto auto;
`;

export const TotalPrice = styled.div`
    margin: 14px auto 0;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    max-width: 430px;

    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 8px;
    }
`;

export const TotalPriceLabel = styled(Typography).attrs({ variant: 'medium16' })``;

export const TotalPriceValue = styled(Typography).attrs({ variant: 'medium16' })``;

export const ButtonAction = styled(Button)`
    margin: 48px auto 0;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 28px;
    }
`;
