import React, { FC } from 'react';
import { Form } from 'react-final-form';
import {
    InputsWrapper,
    ModalClaimWrapper,
    ModalSubtitle,
    ModalTitle,
    SubmitButton,
} from '@components/ModalClaimConnection/styles';
import { useIntl } from 'react-intl';
import { IClaim, IClaimIntegration, INTEGRATION_FIELDS } from '@store/Claim/types';
import { useSelector } from 'react-redux';
import { getClaimByClaimIntegrationId, getClaimIntegrationById } from '@store/Claim/selectors';
import { getShopId } from '@store/System/selectors';
import useValidationSchema from '@hooks/useValidationSchema';
import { consultationSchema } from '@pages/Main/validations';
import GeneratorInputsForm from '@components/GeneratorInputsForm';
import { useAppDispatch } from '@store';
import { createClaim } from '@store/Claim';
import { removePlusAndGapsToPhone } from '@utils/formatters';
import useLoadingSubmit from '@hooks/useLoadingSubmit';

interface IModalClaimConnection {
    closeModal: () => void;
    isOpen: boolean;
}

const ModalClaimConnection: FC<IModalClaimConnection> = ({ closeModal, isOpen }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();

    const typeRequest = 'request';

    const claim: IClaim = useSelector(getClaimByClaimIntegrationId(typeRequest)) as any;
    const claimIntegration: IClaimIntegration = useSelector(getClaimIntegrationById(typeRequest)) as any;
    const shopId: number = useSelector(getShopId) as any;

    const properties = claimIntegration?.[INTEGRATION_FIELDS.fields]?.[INTEGRATION_FIELDS.properties];

    const validation = useValidationSchema(
        consultationSchema(
            intl,
            properties && Object.keys(properties),
            claimIntegration?.[INTEGRATION_FIELDS.fields]?.[INTEGRATION_FIELDS.required],
        ),
    );

    const onSubmit = async (formValues: { [key: string]: string }) => {
        toggleLoadingSubmit();

        const { payload } = await dispatch(
            createClaim({
                shopClaimId: claim?.id,
                shopId,
                fields: { ...formValues, phone: formValues?.phone && removePlusAndGapsToPhone(formValues?.phone) },
            }),
        );

        toggleLoadingSubmit();

        if (payload?.shopId) {
            closeModal();
        }
    };

    return (
        <ModalClaimWrapper closeModal={closeModal} isOpen={isOpen}>
            <Form validate={validation} onSubmit={onSubmit}>
                {({ handleSubmit, valid }) => (
                    <form>
                        <ModalTitle>{intl.formatMessage({ defaultMessage: 'Заявка на подключение' })}</ModalTitle>
                        <ModalSubtitle>
                            {intl.formatMessage({
                                defaultMessage: 'Чтобы подключить приём платежей на свой сайт, заполните форму',
                            })}
                        </ModalSubtitle>
                        <InputsWrapper>
                            <GeneratorInputsForm properties={properties} />
                        </InputsWrapper>
                        <SubmitButton isLoading={isLoadingSubmit} disabled={!valid} onClick={handleSubmit}>
                            {intl.formatMessage({ defaultMessage: 'Отправить заявку' })}
                        </SubmitButton>
                    </form>
                )}
            </Form>
        </ModalClaimWrapper>
    );
};

export default ModalClaimConnection;
