import styled from 'styled-components';
import Typography from '@components/Typography';

export const ToastWrapper = styled.div`
    position: fixed;
    z-index: 4;
    right: 50px;
    top: 50px;
    width: 457px;
    max-height: 100px;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.secondary};
    background: ${({ theme }) => theme.colors.base()};
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 32px;
    padding: 28px 24px 28px 30px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 95%;
        right: 10px;
    }
`;

export const Title = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.white()};
    width: 100%;
`;

export const ButtonClose = styled.button`
    height: 24px;
    svg {
        path {
            stroke: ${({ theme }) => theme.colors.white()};
        }
    }
`;
