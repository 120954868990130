import React, { useEffect, useState } from 'react';
import useClickOutside from '@hooks/useClickOutside';
import { useAppDispatch } from '@store';
import { setLanguage } from '@store/System';
import { useSelector } from 'react-redux';
import { getLanguage } from '@store/System/selectors';
import { ArrowDown, ButtonShowLanguages, LanguagesList, LanguageTitle, LanguageWrapper } from './styles';
import LanguageItem from '../LanguageItem';
import { languageFlags, titleLanguage } from '../../types';

const LanguageDropDown = () => {
    const dispatch = useAppDispatch();
    const language = useSelector(getLanguage);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(language);

    useEffect(() => {
        dispatch(setLanguage(selectedLanguage));
    }, [dispatch, selectedLanguage]);

    const onClickHideLanguageList = () => setIsOpen(false);
    const onClickButtonToggleLanguages = () => setIsOpen(!isOpen);

    const languageRef = useClickOutside(() => {
        onClickHideLanguageList();
    });

    return (
        <LanguageWrapper ref={languageRef}>
            <ButtonShowLanguages type="button" onClick={onClickButtonToggleLanguages}>
                {languageFlags[selectedLanguage]}
                <LanguageTitle>{titleLanguage[selectedLanguage]}</LanguageTitle>
                <ArrowDown $isOpen={isOpen} />
            </ButtonShowLanguages>
            <LanguagesList isOpen={isOpen}>
                {Object.keys(languageFlags).map((itemFlag) => (
                    <LanguageItem
                        onClickHideLanguageList={onClickHideLanguageList}
                        setSelectedLanguage={setSelectedLanguage}
                        isSelected={itemFlag === selectedLanguage}
                        language={itemFlag}
                        key={itemFlag}
                    />
                ))}
            </LanguagesList>
        </LanguageWrapper>
    );
};

export default LanguageDropDown;
