import React from 'react';
import Button from '@components/Button';
import AvatarIcon from '@assets/icons/avatar.svg';
import DesktopIcon from '@assets/icons/desktop.svg';
import { registrationLinkByEnvironment } from '@utils/constants';
import { useSelector } from 'react-redux';
import { getLanguage, getTypeEnvironment } from '@store/System/selectors';
import { useIntl } from 'react-intl';
import { invoiceBoxURL, personalDataProcessingPolice } from '@pages/Payment/types';
import { ButtonPrivacy, ButtonsWrapper, FooterContent, FooterWrapper } from './styles';
import Environment from '../Enviroment';

const Footer = () => {
    const intl = useIntl();

    const environment = useSelector(getTypeEnvironment);
    const language = useSelector(getLanguage);

    return (
        <FooterWrapper>
            <FooterContent>
                <Environment />
                <ButtonsWrapper>
                    <ButtonPrivacy type="link" href={personalDataProcessingPolice[language]}>
                        {intl.formatMessage({ defaultMessage: 'Обработка персональных данных' })}
                    </ButtonPrivacy>
                    <Button href={registrationLinkByEnvironment[environment]} icon={<AvatarIcon />} type="link">
                        {intl.formatMessage({ defaultMessage: 'Регистрация' })}
                    </Button>
                    <Button icon={<DesktopIcon />} type="link" href={invoiceBoxURL[language]}>
                        {intl.formatMessage({ defaultMessage: 'На сайт Инвойсбокс' })}
                    </Button>
                </ButtonsWrapper>
            </FooterContent>
        </FooterWrapper>
    );
};

export default Footer;
