import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import auth from './Auth';
import good from './Good';
import cart from './Cart';
import paymentMethods from './PaymentMethods';
import order from './Order';
import system from './System';
import claim from './Claim';

const store = configureStore({
    reducer: {
        auth,
        claim,
        good,
        cart,
        paymentMethods,
        order,
        system,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
