import styled from 'styled-components';
import Modal from '@components/Modal';
import Typography from '@components/Typography';
import Button from '@components/Button';

export const ModalClaimWrapper = styled(Modal)``;

export const ModalTitle = styled(Typography).attrs({ variant: 'bold24' })`
    margin-bottom: 14px;
`;

export const ModalSubtitle = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey1()};
    margin-bottom: 20px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 24px;
    }
`;

export const InputsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 14px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row-gap: 10px;
    }
`;

export const SubmitButton = styled(Button)`
    margin: 32px auto 0;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 28px;
    }
`;
