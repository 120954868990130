import { RootState } from '@store';

export const getAllItems = (state: RootState) => state.cart.data?.items;
export const getAllGoods = (state: RootState) => state.cart.data?.goods;

export const getCountItemsById = (itemId: number) => (state: RootState) => {
    const allItems = getAllItems(state);
    return allItems?.find((cartItem) => cartItem.goodId === itemId)?.count || 0;
};

export const getCountAllItems = (state: RootState) => {
    const allItems = getAllItems(state);
    return allItems?.reduce((totalCount, currentItem) => totalCount + currentItem.count, 0);
};

export const getTotalAmount = (state: RootState) => state.cart.data?.totalAmount;

export const getCountItemById = (goodId: number) => (state: RootState) => {
    const allBasketItems = getAllItems(state);
    if (allBasketItems) {
        return allBasketItems.find((basketItem) => basketItem.goodId === goodId)?.count;
    }
};

export const getCartId = (state: RootState) => state.cart?.data?.id;
