import { css } from 'styled-components';
import { TTypography } from '@theme/typography';

const start = `
    display: flex;
    align-items: flex-start;
`;

const end = `
    display: flex;
    align-items: flex-end;
`;

const center = `
    display: flex;
    align-items: center;
`;

const mixins = {
    flexStart: css`
        ${start}
    `,
    flexEnd: css`
        ${end}
    `,
    flexCenter: css`
        ${center}
    `,
    flexStartCenter: css`
        ${start};
        justify-content: center;
    `,
    flexCenterCenter: css`
        ${center};
        justify-content: center;
    `,
    flexEndCenter: css`
        ${end};
        justify-content: center;
    `,
    fieldStyles: css`
        color: ${({ theme }) => theme.colors.base()};
        font-size: 14px;
        line-height: 22px;
        border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
        outline: none;
        padding: 13px 20px;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.grey5()};
        transition: ${({ theme }) => theme.decorations.transition.base};

        &::placeholder {
            color: ${({ theme }) => theme.colors.grey2()};
        }
    `,
    fieldLabelStyles: css`
        padding: 0 12px;
        transition: ${({ theme }) => theme.decorations.transition.base};
        margin-bottom: 6px;
    `,
    textOverflowDots: css`
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `,
    mainGrid: css`
        width: 100%;
        margin: 0 auto;
        max-width: 1200px;
    `,
    getTypography: (typographyType: keyof TTypography) => css`
        ${({ theme }) => theme.helpers.getTypography(typographyType)}
    `,
};

export default mixins;
