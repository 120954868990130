import React, { useState } from 'react';
import AreaIcon from '@assets/icons/area.svg';
import ProductIcon from '@assets/icons/product.svg';
import useClickOutside from '@hooks/useClickOutside';
import { enumTypeEnvironment } from '@store/System/types';
import { useSelector } from 'react-redux';
import { getTypeEnvironment } from '@store/System/selectors';
import { useIntl } from 'react-intl';
import {
    ArrowDown,
    EnvironmentIconWrapper,
    EnvironmentList,
    EnvironmentListWrapper,
    EnvironmentWrapper,
    SelectedEnvironmentButton,
    SelectedEnvironmentName,
} from './styles';
import EnvironmentItem from '../EnvironmentItem';

export const environmentIcon = {
    [enumTypeEnvironment.develop]: <AreaIcon />,
    [enumTypeEnvironment.staging]: <AreaIcon />,
    [enumTypeEnvironment.production]: <ProductIcon />,
};

const Environment = () => {
    const intl = useIntl();

    const environmentLabel = {
        [enumTypeEnvironment.develop]: intl.formatMessage({ defaultMessage: 'Тестовая' }),
        [enumTypeEnvironment.staging]: intl.formatMessage({ defaultMessage: 'Стейдж' }),
        [enumTypeEnvironment.production]: intl.formatMessage({ defaultMessage: 'Продуктовая' }),
    };

    const [isOpenEnvironmentList, setIsOpenEnvironmentList] = useState(false);

    const currentEnvironment = useSelector(getTypeEnvironment);

    const hideEnvironmentList = () => setIsOpenEnvironmentList(false);

    const environmentRef = useClickOutside(() => {
        hideEnvironmentList();
    });

    return (
        <EnvironmentWrapper ref={environmentRef}>
            {intl.formatMessage({
                defaultMessage: 'Среда:',
                description: 'не день недели, а среда запуска приложения, например тестовая, стейдж, продуктовая',
            })}
            <EnvironmentListWrapper>
                <SelectedEnvironmentButton
                    type="button"
                    onClick={() => {
                        setIsOpenEnvironmentList(!isOpenEnvironmentList);
                    }}
                >
                    <EnvironmentIconWrapper>{environmentIcon[currentEnvironment]}</EnvironmentIconWrapper>
                    <SelectedEnvironmentName>{environmentLabel[currentEnvironment]}</SelectedEnvironmentName>
                    <ArrowDown $isOpen={isOpenEnvironmentList} />
                </SelectedEnvironmentButton>
                <EnvironmentList isOpen={isOpenEnvironmentList}>
                    {Object.keys(environmentLabel)
                        .filter((environment) => {
                            if (currentEnvironment === enumTypeEnvironment.production) {
                                if (environment === enumTypeEnvironment.production) {
                                    return environment;
                                }
                            } else {
                                return environment;
                            }
                            return null;
                        })
                        .map((environment) => (
                            <EnvironmentItem
                                key={environment}
                                isActive={environment === currentEnvironment}
                                environment={environment}
                                environmentLabel={environmentLabel}
                            />
                        ))}
                </EnvironmentList>
            </EnvironmentListWrapper>
        </EnvironmentWrapper>
    );
};

export default Environment;
