import styled from 'styled-components';
import Typography from '@components/Typography';

export const BasketItemWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    grid-column-gap: 24px;
    @media ${({ theme }) => theme.breakpoints.xl} {
        max-width: 292px;
        min-width: 230px;
        overflow: hidden;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        max-width: 100%;
    }
`;

export const ImageWrapper = styled.div`
    padding: 10px;
    flex-shrink: 0;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    overflow: hidden;
    width: 96px;
    height: 96px;
    background: ${({ theme }) => theme.colors.white()};
`;

export const BasketItemImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
`;

export const BasketItemContent = styled.div`
    padding: 4px 0 2px 0;
    width: 100%;
    height: 100%;
    ${({ theme }) => theme.mixins.flexStart};
    flex-direction: column;
    justify-content: space-between;
`;

export const BasketItemTitle = styled(Typography).attrs({ variant: 'medium16' })`
    max-height: 44px;
    overflow: hidden;
`;

export const BasketItemFooter = styled(Typography).attrs({ variant: 'regular16' })``;
