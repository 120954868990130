import styled, { keyframes } from 'styled-components';

const leftEyeKeyframe = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.42);
  }
`;

const rightEyeKeyframe = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.78);
  }
`;

export const LoadingWrapper = styled.div`
    height: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    & #left-eye {
        animation: 0.5s ease-in-out infinite alternate ${leftEyeKeyframe};
        transform-origin: 17px 28px;
    }

    & #right-eye {
        animation: 0.5s ease-in-out infinite alternate ${rightEyeKeyframe};
        transform-origin: 76px 18px;
    }
`;
