import React, { FC, InputHTMLAttributes } from 'react';
import { HiddenInput, RadioContainer, RadioIcon, RadioLabel, StyledRadio } from '@components/Radio/styles';

interface IRadioProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    checked: boolean;
    disabled?: boolean;
}

const Radio: FC<IRadioProps> = ({ disabled, children, className, checked, ...props }) => (
    <RadioLabel>
        <RadioContainer className={className}>
            <HiddenInput checked={checked} {...props} type="radio" disabled={disabled} />
            <StyledRadio checked={checked} disabled={disabled}>
                <RadioIcon />
            </StyledRadio>
        </RadioContainer>
        {children}
    </RadioLabel>
);

export default Radio;
