import styled from 'styled-components';
import Typography from '@components/Typography';
import ArrowDownImage from '@assets/icons/arrowDown.svg';

export const LanguageWrapper = styled.div`
    position: relative;
    padding: 1px 0;
`;

export const LanguageTitle = styled(Typography).attrs({ variant: 'regular14' })`
    margin: 0 8px 0 11px;
`;

interface IArrowDown {
    $isOpen: boolean;
}

export const ArrowDown = styled(ArrowDownImage)<IArrowDown>`
    transform: ${({ $isOpen }) => ($isOpen ? 'rotateZ(180deg)' : 'none')};
    transition: ${({ theme }) => theme.decorations.transition?.base};
`;

export const ButtonShowLanguages = styled.button`
    ${({ theme }) => theme.mixins.flexCenter};
    padding: 0 8px 0 3px;
`;

interface ILanguagesList {
    isOpen: boolean;
}

export const LanguagesList = styled.div<ILanguagesList>`
    position: absolute;
    top: 36px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
    background: ${({ theme }) => theme.colors.white()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    padding: ${({ isOpen }) => (isOpen ? '8px 0' : '0')};
    max-height: ${({ isOpen }) => (isOpen ? '100px' : '0')};
    transition: ${({ theme }) => theme.decorations.transition.base};
    overflow: hidden;
`;
