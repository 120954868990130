import React, { FC } from 'react';
import { IconClickableContainer, IconContainer } from '@components/Input/components/InputIconContainer/styles';

interface IInputIconContainer {
    onClick?: () => void;
    iconIsDisabled?: boolean;
}

const InputIconContainer: FC<IInputIconContainer> = ({ onClick, iconIsDisabled, children }) =>
    onClick ? (
        <IconClickableContainer onClick={onClick} disabled={iconIsDisabled}>
            {children}
        </IconClickableContainer>
    ) : (
        <IconContainer>{children}</IconContainer>
    );

export default InputIconContainer;
