import React, { FC } from 'react';
import {
    ButtonClose,
    ButtonNotification,
    Buttons,
    NotificationHead,
    NotificationTitle,
    NotificationWrapper,
} from '@pages/Payment/components/NotificationAddingItemsToBasket/styles';
import ShopLogoImage from '@assets/icons/shopLogo.svg';
import IconClose from '@assets/icons/closeSquare.svg';
import useClickOutside from '@hooks/useClickOutside';
import { useSelector } from 'react-redux';
import { getIsVisibleHeader } from '@store/System/selectors';
import { useIntl } from 'react-intl';

interface INotificationAddingItemsToBasket {
    hideNotificationAdding: () => void;
    goToNextStep: () => void;
}

const NotificationAddingItemsToBasket: FC<INotificationAddingItemsToBasket> = ({
    goToNextStep,
    hideNotificationAdding,
}) => {
    const intl = useIntl();
    const isVisibleHeader = useSelector(getIsVisibleHeader);

    const notificationRef = useClickOutside(hideNotificationAdding);

    const createOrder = () => {
        goToNextStep();
        hideNotificationAdding();
    };

    return (
        <NotificationWrapper isVisibleHeader={isVisibleHeader} ref={notificationRef}>
            <NotificationHead>
                <ShopLogoImage />
                <ButtonClose onClick={hideNotificationAdding}>
                    <IconClose />
                </ButtonClose>
            </NotificationHead>
            <NotificationTitle>{intl.formatMessage({ defaultMessage: 'Товар добавлен в корзину' })}</NotificationTitle>
            <Buttons>
                <ButtonNotification onClick={createOrder}>
                    {intl.formatMessage({ defaultMessage: 'Оформить заказ' })}
                </ButtonNotification>
                <ButtonNotification onClick={hideNotificationAdding} type="secondary">
                    {intl.formatMessage({ defaultMessage: 'Продолжить покупки' })}
                </ButtonNotification>
            </Buttons>
        </NotificationWrapper>
    );
};

export default NotificationAddingItemsToBasket;
