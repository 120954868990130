import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderWrapper = styled.div`
    background: ${({ theme }) => theme.colors.grey4()};
    padding: 23px 0 19px 0;

    @media ${({ theme }) => theme.breakpoints.xl} {
        padding: 23px 36px 19px 36px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 23px 16px 19px 16px;
    }
`;

export const HeaderContent = styled.div`
    height: 100%;
    ${({ theme }) => theme.mixins.mainGrid};
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
`;

export const LogoButton = styled(Link)`
    margin-top: 3px;
    height: 27px;
`;
