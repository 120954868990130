import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { IOrder, IOrderResponse } from '@store/Order/types';
import { getOrdersRequest, setOrderRequest } from '@store/Order/http';
import { fetchCart } from '@store/Cart';
import createAsyncThunkWithErrorHandler from '@utils/helpers/createAsyncThunkWithErrorHandler';

export const fetchOrders = createAsyncThunkWithErrorHandler('order/getAll', async () => {
    const { data } = await getOrdersRequest();
    return data;
});

export const createOrder = createAsyncThunkWithErrorHandler('order/create', async (orderData: IOrder, thunkAPI) => {
    const { data } = await setOrderRequest(orderData);
    if (data) {
        thunkAPI.dispatch(fetchCart());
        return data;
    }
});

export const orderAdapter = createEntityAdapter<IOrderResponse>();

const initialState = orderAdapter.getInitialState({});

const order = createSlice({
    name: 'order',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createOrder.fulfilled, (state, { payload }) => {
            const orderResponse = payload?.data;
            if (orderResponse) {
                orderAdapter.addOne(state, orderResponse);
            }
        });
        builder.addCase(fetchOrders.fulfilled, (state, { payload }) => {
            const orderResponse = payload?.data;
            if (orderResponse) {
                orderAdapter.setMany(state, orderResponse);
            }
        });
    },
});

export default order.reducer;
