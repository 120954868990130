import styled from 'styled-components';
import Typography from '@components/Typography';
import Button from '@components/Button';

interface INotificationWrapper {
    isVisibleHeader: boolean;
}

export const NotificationWrapper = styled.div<INotificationWrapper>`
    width: 501px;
    background: ${({ theme }) => theme.colors.white()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.notification};
    padding: 0 22px 40px 40px;
    position: fixed;
    z-index: 3;
    left: 50%;
    top: ${({ isVisibleHeader }) => (isVisibleHeader ? '82px' : '12px')};
    transform: translateX(-50%);
    transition: ${({ theme }) => theme.decorations.transition?.secondary};

    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 309px;
        top: ${({ isVisibleHeader }) => (isVisibleHeader ? '78px' : '6px')};
        padding: 0 24px 32px 24px;
    }
`;

export const NotificationHead = styled.div`
    position: relative;
    justify-content: space-between;
    padding: 30px 0 27px 0;
`;

export const ButtonClose = styled.button`
    position: absolute;
    right: -6px;
    top: 16px;
    &:hover {
        path {
            stroke: ${({ theme }) => theme.colors.grey1()};
        }
    }

    &:active,
    &:focus {
        path {
            stroke: ${({ theme }) => theme.colors.dark3()};
        }
    }
`;

export const NotificationTitle = styled(Typography).attrs({ variant: 'regular20' })`
    margin-bottom: 32px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-bottom: 20px;
    }
`;

export const Buttons = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 30px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        flex-direction: column;
        grid-row-gap: 12px;
    }
`;

export const ButtonNotification = styled(Button)`
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 100%;
    }
`;
