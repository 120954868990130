import React, { FC } from 'react';
import { PaymentProgressWrapper, DashedLine, ProgressCircle } from '@pages/Payment/components/PaymentProgress/styles';
import { stepsPayment } from '@pages/Payment/types';

interface IPaymentProgress {
    currentPaymentStepIndex: number;
}

const PaymentProgress: FC<IPaymentProgress> = ({ currentPaymentStepIndex }) => {
    const arrSteps = Object.values(stepsPayment).filter((x) => typeof x === 'number');

    return (
        <PaymentProgressWrapper>
            <DashedLine />
            {arrSteps.map((step, index) => (
                <ProgressCircle
                    key={step}
                    isActive={step === currentPaymentStepIndex}
                    isPassed={step < currentPaymentStepIndex}
                >
                    {index + 1}
                </ProgressCircle>
            ))}
        </PaymentProgressWrapper>
    );
};
export default PaymentProgress;
