export enum INTEGRATION_FIELDS {
    id = 'id',
    title = 'title',
    settings = 'settings',
    type = 'type',
    additionalProperties = 'additionalProperties',
    required = 'required',
    properties = 'properties',
    fields = 'fields',
}

export interface ISettingFields {
    [INTEGRATION_FIELDS.type]: string;
    [INTEGRATION_FIELDS.additionalProperties]: boolean;
    [INTEGRATION_FIELDS.required]: string[];
    [INTEGRATION_FIELDS.properties]: { [key: string]: { [INTEGRATION_FIELDS.type]: string } };
}

export interface IClaimIntegration {
    [INTEGRATION_FIELDS.id]: string;
    [INTEGRATION_FIELDS.title]: string;
    [INTEGRATION_FIELDS.settings]: ISettingFields;
    [INTEGRATION_FIELDS.fields]: ISettingFields;
}

export enum EXTENDED_DATA_FIELDS {
    entity = 'entity',
    type = 'type',
    data = 'data',
}

export interface IExtendedData<T> {
    [EXTENDED_DATA_FIELDS.entity]: string;
    [EXTENDED_DATA_FIELDS.type]: string;
    [EXTENDED_DATA_FIELDS.data]: T[];
}

export enum CLAIM_FIELDS {
    id = 'id',
    title = 'title',
    description = 'description',
    shopId = 'shopId',
    claimIntegrationId = 'claimIntegrationId',
}

export interface IClaim {
    [CLAIM_FIELDS.id]: number;
    [CLAIM_FIELDS.title]: string;
    [CLAIM_FIELDS.description]: string;
    [CLAIM_FIELDS.claimIntegrationId]: string;
    [CLAIM_FIELDS.shopId]: number;
}

export interface IClaimRequestData {
    shopClaimId: number;
    shopId: number;
    fields: { [key: string]: string | number };
}
