import React, { FC, useEffect } from 'react';
import LogoDarkRU from '@assets/icons/logoDarkRU.svg';
import LogoDarkEN from '@assets/icons/logoDarkEN.svg';
import { Routes } from '@pages/constants';
import handleViewport from 'react-in-viewport';
import { useSelector } from 'react-redux';
import { getIsVisibleHeader, getLanguage } from '@store/System/selectors';
import { useAppDispatch } from '@store';
import { setIsHeaderInvisible, setIsHeaderVisible } from '@store/System';
import { HeaderContent, HeaderWrapper, LogoButton } from './styles';
import LanguageDropDown from '../LaguageDropDown';
import { enumLanguage } from '../../types';

interface IHeader {
    forwardedRef?: React.RefObject<HTMLInputElement>;
    inViewport?: boolean;
}

const localeLogo = {
    [enumLanguage.ru]: LogoDarkRU,
    [enumLanguage.en]: LogoDarkEN,
};

const Header: FC<IHeader> = ({ inViewport, forwardedRef }) => {
    const dispatch = useAppDispatch();
    const isVisibleHeader = useSelector(getIsVisibleHeader);

    const language = useSelector(getLanguage);

    const Logo = localeLogo[language];

    useEffect(() => {
        if (isVisibleHeader !== inViewport) {
            if (inViewport) {
                dispatch(setIsHeaderVisible());
            } else {
                dispatch(setIsHeaderInvisible());
            }
        }
    }, [dispatch, inViewport, isVisibleHeader]);

    return (
        <HeaderWrapper ref={forwardedRef}>
            <HeaderContent>
                <LogoButton to={Routes.main}>
                    <Logo />
                </LogoButton>
                <LanguageDropDown />
            </HeaderContent>
        </HeaderWrapper>
    );
};

export default handleViewport(Header);
