import styled, { css } from 'styled-components';
import Typography from '@components/Typography';
import InputMask from 'react-input-mask';

interface IInputLabelProps {
    inFocus: boolean;
    hasError: boolean;
}

export const InputLabel = styled(Typography).attrs({ variant: 'medium12' })<IInputLabelProps>`
    ${({ theme }) => theme.mixins.fieldLabelStyles};
    color: ${({ theme, inFocus, hasError }) => theme.helpers.getFieldLabelColor(theme, inFocus, hasError)};
`;

export const inputStyles = css`
    ${({ theme }) => theme.mixins.fieldStyles};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }
`;

interface IInputField {
    $isRounded: boolean;
}

export const MaskField = styled(InputMask)<IInputField>`
    ${inputStyles};
    border-radius: ${({ theme, $isRounded }) => ($isRounded ? theme.decorations.borderRadius?.base : '0')}px;
`;

export const InputField = styled.input<IInputField>`
    ${inputStyles};
    border-radius: ${({ theme, $isRounded }) => ($isRounded ? theme.decorations.borderRadius?.base : '0')}px;
`;

interface IInputWrapperProps {
    disabled: boolean;
    hasError: boolean;
    isRounded: boolean;
}

export const InputWrapper = styled.div<IInputWrapperProps>`
    position: relative;
    width: 100%;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    &:hover {
        & ${InputLabel} {
            color: ${({ theme, hasError }) => (hasError ? theme.colors.red() : theme.colors.base())};
        }

        & ${InputField} {
            background: ${({ theme, isRounded }) => (!isRounded ? theme.colors.white() : theme.colors.grey4())};
            border-color: ${({ theme, isRounded, hasError }) =>
                theme.helpers.getBorderColorInputToHover(theme, isRounded, hasError)};
        }
        & ${MaskField} {
            background: ${({ theme, isRounded }) => (!isRounded ? theme.colors.white() : theme.colors.grey4())};
            border-color: ${({ theme, isRounded, hasError }) =>
                theme.helpers.getBorderColorInputToHover(theme, isRounded, hasError)};
        }
    }
`;

interface IInputContainerProps {
    hasError: boolean;
    hasIcon: boolean;
    inFocus: boolean;
    isRounded: boolean;
}

export const InputContainer = styled.div<IInputContainerProps>`
    & input {
        background: ${({ theme, inFocus, hasError, isRounded }) =>
            theme.helpers.getBgColorInputContainer(theme, inFocus, hasError, isRounded)};
        padding-right: ${({ hasIcon }) => (hasIcon ? 56 : 18)}px;
        border: 1px solid
            ${({ theme, hasError, isRounded, inFocus }) =>
                theme.helpers.getBorderColorInputContainer(theme, hasError, inFocus, isRounded)};
        /* Change autocomplete styles in WebKit */

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            border: 1px solid ${({ theme, hasError }) => (hasError ? theme.colors.red() : 'none')};
            -webkit-text-fill-color: inherit;
            background-color: inherit;
            transition: background-color 5000s ease-in-out 0s;
        }
    }
`;
