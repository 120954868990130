import { ONLY_NUMBERS_AND_PLUS_REGEX, PHONE_REGEX } from '@utils/constants';

export const removePlusAndGapsToPhone = (phone: string) => {
    if (phone) {
        const result = phone.split(' ').join('');
        if (result[0] === '+') {
            return result.slice(1);
        }
        return result;
    }
    return '';
};

export const phoneFieldFormatters = {
    parse: (value: string) => value?.replace(PHONE_REGEX, '7').replace(ONLY_NUMBERS_AND_PLUS_REGEX, '').slice(0, 12),
    format: (value: string) => value?.replace(/^7/, '+7'),
};
