import { useState } from 'react';

interface IUseFocusFieldProps<Event> {
    isFocus: boolean;
    onFocus?: (e: Event) => void;
    onBlur?: (e: Event) => void;
}

const useFocusField = <Event>({ isFocus, onFocus, onBlur }: IUseFocusFieldProps<Event>) => {
    const [inFocus, setInFocus] = useState(isFocus);

    const onFocusHandler = (e: Event) => {
        setInFocus(true);
        if (onFocus) onFocus(e);
    };

    const onBlurHandler = (e: Event) => {
        setInFocus(false);
        if (onBlur) onBlur(e);
    };

    return { inFocus, onFocusHandler, onBlurHandler };
};

export default useFocusField;
