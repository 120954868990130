import yup from '@utils/validations';
import { IntlShape } from 'react-intl';

const getValidateByFields = (fieldName: string, intl: IntlShape) => {
    switch (fieldName) {
        case 'phone': {
            return yup.string().isValidPhone(intl);
        }
        case 'email': {
            return yup.string().email();
        }
        case 'vatNumber': {
            return yup.string().isValidInn(intl);
        }
        default: {
            return yup.string();
        }
    }
};

export const consultationSchema = (intl: IntlShape, fields: string[], required: string[]) => {
    const validateArray = fields?.map((field) => {
        if (field !== 'body') {
            const isRequired = Boolean(required.find((requiredField) => requiredField === field));
            const validationField = getValidateByFields(field, intl);
            return { [field]: isRequired ? validationField.required() : validationField };
        }
        return null;
    });

    // @ts-ignore
    const validateObject = validateArray && Object.assign(...validateArray);

    // @ts-ignore
    return yup.object().shape(validateObject);
};
