type TBorderRadius = Record<string, number>;

type TBoxShadow = Record<string, string>;

type TTransition = Record<string, string>;

export type TDecorations = {
    borderRadius: TBorderRadius;
    boxShadow: TBoxShadow;
    transition: TTransition;
};

export const transitionsDelayMS = {
    base: 200,
    secondary: 400,
};

const decorations: TDecorations = {
    borderRadius: {
        xs: 5,
        m: 8,
        base: 10,
        max: 16,
    },
    boxShadow: {
        base: '0px 4px 24px rgba(53, 63, 71, 0.12)',
        light: '0px 3px 8px rgba(0, 0, 0, 0.15)',
        notification: '0px 73px 90px -46px rgba(0, 0, 0, 0.25)',
    },
    transition: {
        base: `all ${transitionsDelayMS.base}ms ease-in-out`,
        secondary: `all ${transitionsDelayMS.secondary}ms ease-in-out`,
    },
};

export default decorations;
