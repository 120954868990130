import React, { FC, InputHTMLAttributes } from 'react';
import {
    CheckboxContainer,
    CheckboxIcon,
    CheckboxLabel,
    CheckboxLabelText,
    HiddenCheckbox,
    StyledCheckbox,
} from '@components/Checkbox/styles';

export interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    checked: boolean;
    label?: string;
    disabled?: boolean;
}

const Checkbox: FC<ICheckboxProps> = ({ disabled = false, className, checked = false, ...props }) => (
    <CheckboxLabel checked={checked} disabled={disabled} className={className}>
        <CheckboxContainer>
            <HiddenCheckbox type="checkbox" disabled={disabled} checked={checked} {...props} />
            <StyledCheckbox>
                <CheckboxIcon />
            </StyledCheckbox>
        </CheckboxContainer>
        {props.label && <CheckboxLabelText>{props.label}</CheckboxLabelText>}
    </CheckboxLabel>
);

export default React.memo(Checkbox);
